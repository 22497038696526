import React from "react";

const WithUs = () => {
  return <>
    <div className="section stylle-shadow">
      <div className="page-padding">
        <div className="container-large padding-vertical padding-huge">
          <div className="flex-center padding-horizontal padding-large">
            <h2 className="heading-xlarge margin-bottom margin-xsmall">Trabalhe conosco</h2>
            <div className="text-size-large text-color-dark-gray text-center">Faça parte de uma equipe distribuída que valoriza a independência e a heterodoxia:</div>
            <div className="buttons-wrapper margin-top margin-small">
              <a data-w-id="a464bae0-9cd7-c5d7-3e08-322a61404606" href="https://forms.gle/VbpLbcFwvgGRRLyeA" target="_blank" rel="noopener noreferrer" className="button-medium-secondary w-inline-block">
                <div>Sou designer</div>
              </a>
              <a data-w-id="b2c4237a-1ebe-1df2-15ce-cdb7907c2d66" href="https://forms.gle/wpS7MKPeTNYD2fth7" target="_blank" rel="noopener noreferrer" className="button-medium-secondary w-inline-block">
                <div>Programo</div>
              </a>
              <a data-w-id="05945a63-15f4-7b37-ddf6-9ea5d4cebbf3" href="https://forms.gle/RrVaky4RfkVhr4jGA" target="_blank" rel="noopener noreferrer" className="button-medium-secondary w-inline-block">
                <div>Escrevo</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default WithUs;