import React, { useState } from "react";

import api from "../../../services/global/api-bot";

const ModalChat = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [display, setDisplay] = useState(false);
  const onChange = (ev) => {
    setQuestion(ev.target.value);
  };
  const getAnswer = async () => {
    setLoading(true);
    setDisplay(false);
    const response = await api.get(`/ask?query=${question}&history=false`);
    setAnswer(response.data);
    setLoading(false);
    setDisplay(true);
  };
  return (
    <>
      <div className="divisor margin-vertical margin-xsmall"></div>
      <div className="duo-grid margin-bottom margin-medium">
        <div>
          <div
            id="w-node-_1f98e407-54c1-8087-30a4-f4672b2eca2e-78ec268d"
            className="text-size-small text-color-dark-gray margin-bottom margin-xsmall text-style-allcaps"
          >
            <input
              type="text"
              name="question"
              className="text-field w-input"
              maxLength={256}
              value={question}
              onChange={onChange}
              placeholder="Pergunte me o que quiser..."
            />
          </div>
          {isLoading ? <div class="loading">Estou pensando...</div> : null}
          {display ? (
            <textarea
              value={answer}
              readonly
              rows="25"
              cols="40"
              className="text-field w-input"
            />
          ) : null}
        </div>
      </div>
      <button
        onClick={getAnswer}
        style={{
          float: "right",
          border: "2px solid white",
          backgroundColor: "#3de8a0",
          color: "black",
          padding: "14px 28px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        Enviar
      </button>
    </>
  );
};

export default ModalChat;
