import React from "react";
import { connect } from "react-redux";

import VRCForm from "./vrc";
import arrow from "../../../images/arrow-back-outline-1-1.svg";
import close from "../../../images/close-outline-1.svg";
import {
  openModal,
  setModal,
  setEmail,
  setTerms,
  setPrice,
  setDiscount,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
} from "../../../store/actions/modals";

const mapState = (state) => ({
  modals: state.modals,
});

const ModalVRCForm = ({
  modals,
  openModal,
  setModal,
  setEmail,
  setTerms,
  setDiscount,
  setPrice,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
}) => {
  const path = window.location.pathname;

  const navigateModal = async (kind) => {
    openModal(true);
    setModal(0);
  };

  const ModalDisplay = () => {
    return (
      <VRCForm
        modals={modals}
        openModal={openModal}
        setPrice={setPrice}
        setPPlan={setPPlan}
        setPeriod={setPeriod}
        setOldPrice={setOldPrice}
        modalType={modalType}
        landingPageModal={landingPageModal}
        setInstallments={setInstallments}
      />
    );
  };

  return (
    <>
      <div
        className={"modal-wrapper"}
        style={{ backgroundColor: path === "/vrc" && "#000" }}
      >
        <div className="modal-container padding-xsmall">
          <div className="flex-modal">
            <div
              className="icon-back-wrapper"
              onClick={() => {
                navigateModal(0);
              }}
            >
              <img
                src={arrow}
                loading="lazy"
                alt=""
                className={
                  modals.type === 0 ? "icon-back display-none" : "icon-back"
                }
              />
            </div>

            <img
              src={close}
              loading="lazy"
              alt=""
              className="icon-close"
              style={{ display: path === "/pagamento" && "none" }}
              onClick={() => openModal(false)}
            />
          </div>
          <div className={modals.type === 0 ? "form-wrapper-scroll-modal" : ""}>
            <div className="modal-inner modal-inner-padding">
              {modals.isOpened && ModalDisplay()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapState, {
  setModal,
  openModal,
  setEmail,
  setTerms,
  setPrice,
  setDiscount,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
})(ModalVRCForm);
