import React from "react";

import videomp4 from "../../videos/0_discord-community_v2_1-transcode.mp4";
import videowebm from "../../videos/0_discord-community_v2_1-transcode.webm";
import discord from "../../videos/0_discord-community_v2_1-poster-00001.jpg";

// import discordScreen from '../../../images/discord-screenshot@2x.png';

import "../../styles/landing-tracks.css";

const Discord = () => {
  return <>
    <div className="section-discord">
      <div className="page-padding">
        <div className="container-large padding-top padding-huge">
          <div className=" align-center">
          <div className="landing-tracks-text-primary-x-large discord-title">Acesse uma comunidade exclusiva</div>
              <div className="landing-tracks-text-second-medium discord-text">As ideias mais lucrativas dos últimos anos foram discutidas por nossos especialistas. Tire suas dúvidas com eles no nosso chat a qualquer hora.</div>
          </div>
          <div
            data-poster-url={discord}
            data-video-urls={videowebm}
            data-autoplay="true"
            data-loop="true"
            data-wf-ignore="true"
            className="bakground-video margin-top margin-xxlarge w-background-video w-background-video-atom"
          >
            <video
              id="97563dd5-7dfa-20a6-06b7-1696427ca7e0-video"
              autoPlay
              loop
              muted
              playsInline
              data-wf-ignore="true"
              data-object-fit="cover"
            >
              <source src={videomp4} data-wf-ignore="true" />
              <source src={videowebm} data-wf-ignore="true" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Discord;