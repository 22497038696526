import React from "react";

import iconLeft from "../../../../images/chevron-backward-24px.svg";


const PrevArrow = (props) => {
  const {className, onClick} = props;
  return <>
    <div 
      className={className} 
      onClick={onClick}
    >
    <img src={iconLeft} alt="arrow left" />
    </div>
  </>
}

export default PrevArrow;