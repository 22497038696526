import React, { useState, useEffect } from "react";

import checkmark from "../../../images/checkmark-outline-2.svg";
import moedaPro from "../../../images/icon-moeda.svg";

const CheckMark = (props) => {
  return (
    <>
      <div className="flex-horizontal margin-bottom margin-xxsmall">
        <img
          src={checkmark}
          loading="lazy"
          alt=""
          style={{ objectFit: "contain" }}
        />
        <div className="text-size-medium text-color-dark-gray margin-left margin-xxsmall">
          {`${props.miniCursos ? props.miniCursos : ""} ${props.text}`}
        </div>
      </div>
    </>
  );
};

const ModalMainVitalicy = ({
  modals,
  setPrice,
  setPPlan,
  setOldPrice,
}) => {
  const [priceQuadri, setPriceQuadri] = useState(0);
  const [priceAnual, setPriceAnual] = useState(0);
  console.log(modals)

  useEffect(() => {
    setPriceQuadri(639.6);
    setPriceAnual(1438.8);
    setPPlan(modals.period ? 4 : 5);
    setOldPrice(modals.period ? 639.6 : 1438.8);
    const getPrice = () => {
      if (modals.period) {
        return modals.discount
          ? priceQuadri - (priceQuadri * modals.discount) / 100
          : priceQuadri;
      }
      return modals.discount
        ? priceAnual - (priceAnual * modals.discount) / 100
        : priceAnual;
    };
    setPrice(getPrice());
  }, [
    priceQuadri,
    priceAnual,
    modals.discount,
    modals.modalType,
    modals.period,
    setPrice,
    setOldPrice,
    setPPlan,
  ]);

  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  return (
    <>
      <div className="flex-horizontal justify" style={{alignItems: "center"}}>
        <h2 className="heading-xlarge heading-xlarge-modals">
          Paradigma Pro Vitalício
        </h2>
        <div>
          {modals.discount !== undefined ? (
            <div
              className="text-size-xxlarge"
              style={{
                textDecoration: "line-through",
                display: modals.oldPrice,
              }}
            >
              {formatter.format(modals.oldPrice)}
            </div>
          ) : null}
          <div className="flex-horizontal-center flex-horizontal-end">
            <div className="text-size-xxlarge" style={{ fontSize: "20px" }}>
              {formatter.format(modals.price)}
            </div>
          </div>
          {modals.discount !== undefined ? (
            <>
              <div
                className="text-size-regular text-font-manrope text-color-dark-gray text-align-right"
                style={{ display: modals.price }}
              >
                {`Cupom de ${modals.discount}% aplicado.`}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="divisor margin-vertical margin-xsmall"></div>
      <div className="duo-grid margin-bottom">
        <div>
          <div
            id="w-node-_1f98e407-54c1-8087-30a4-f4672b2eca2e-78ec268d"
            className="text-size-small text-color-dark-gray margin-bottom margin-xsmall text-style-allcaps"
          >
            Incluso:
          </div>
            <>
              {/* <CheckMark text="Carteiras simuladas" />
              <CheckMark text="+20 indicadores exclusivos" />
              <CheckMark text="Chat direto com analistas, 24/7" />
              <CheckMark text="Boletins, relatórios e cursos" /> */}

              <CheckMark text="6 mini-cursos: uma formação completa" />
              <CheckMark text="Todas nossas carteiras recomendadas" />
              <CheckMark text="Acompanhamento semanal" />
              <CheckMark text="Relatórios temáticos premium" />
              <CheckMark text="Comunidade exclusiva e suporte técnico" />
              <CheckMark text="Ideias de trade degeneradas" />
            </>
        </div>
        <img
          src={moedaPro}
          loading="lazy"
          id="w-node-_5cf54861-e87c-f46c-deca-19c064730eb6-78ec268d"
          alt=""
          className="icon-money"
        />
      </div>
    </>
  );
};

export default ModalMainVitalicy;
