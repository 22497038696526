export const setTracks = (tracks) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_TRACKS",
      payload: tracks,
    });
  } catch (err) {
    console.log(err);
  }
};
