import React from "react";

import seta from '../../../images/svg-seta.svg';
import setaTop from '../../../images/svg-seta-top.svg';

const Item = ({number, value}) => {
  const numberFormatted = Number(number);
  const active = (24/100)*value;
  const activeFormatted = Math.round(active);

  return <>
    <div className={`item item-${number} ${numberFormatted>activeFormatted ? "disabled" : ""}`}>
      <img src={seta} loading="lazy" alt="" className={`item-icon ${numberFormatted===activeFormatted ? "active" : ""}`}/>
      <div className={`explaner padding-custom1 item-${String(activeFormatted)} ${numberFormatted===activeFormatted ? "active" : ""}`}>
        <div className="text-size-small text-style-allcaps">{`${value}.0 ${value>=50 ? "EUFORIA" : "MEDO"}`}</div>
        <img src={setaTop} loading="lazy" alt="" className="image"/>
      </div>
    </div>
  </>
}

export default Item;