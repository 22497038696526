import React from "react";
import { useState } from "react";
import caretDownOutline from "../../../images/caret-down-outline-1.svg";

const MenuCrypto = ({ coins, setCoins }) => {
  const [order, setOrder] = useState("ASC");

  const sortCoins = (type) => {
    let sortedArray;

    if (order === "ASC") {
      if (type === "total_volume") {
        sortedArray = [...coins].sort(
          (a, b) =>
            a["total_volume"] / a["market_cap"] -
            b["total_volume"] / b["market_cap"]
        );
      }

      if (type === "dumpometro") {
        sortedArray = [...coins].sort(
          (a, b) =>
            a["market_cap"] / a["fully_diluted_valuation"] -
            b["market_cap"] / b["fully_diluted_valuation"]
        );
      }

      if (type !== "total_volume" && type !== "dumpometro") {
        sortedArray = [...coins].sort((a, b) => (a[type] > b[type] ? -1 : 1));
      }
      setCoins(sortedArray);
      setOrder("DSC");
    }

    if (order === "DSC") {
      if (type === "total_volume") {
        sortedArray = [...coins].sort(
          (a, b) =>
            b["total_volume"] / b["market_cap"] -
            a["total_volume"] / a["market_cap"]
        );
      }

      if (type === "dumpometro") {
        sortedArray = [...coins].sort(
          (a, b) =>
            b["market_cap"] / b["fully_diluted_valuation"] -
            a["market_cap"] / a["fully_diluted_valuation"]
        );
      }

      if (type !== "total_volume" && type !== "dumpometro") {
        sortedArray = [...coins].sort((a, b) => (b[type] > a[type] ? -1 : 1));
      }

      setCoins(sortedArray);
      setOrder("ASC");
    }
  };

  return (
    <>
      <div className="cripto-grid">
        <div
          id="w-node-e7389bae-e846-7cc7-77fe-7e34cc5d10e2-49152174"
          className="divisor"
        ></div>
        <div
          className="cripto-box margin-left margin-xxsmall"
          onClick={() => sortCoins("market_cap")}
          style={{ cursor: "pointer" }}
        >
          <div className="text-size-small text-color-dark-gray">#</div>
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("symbol")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Moeda
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("name")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Categorias
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("current_price")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Preço
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("price_change_percentage_1h_in_currency")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Ih
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("price_change_percentage_24h_in_currency")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            24h
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("price_change_percentage_7d_in_currency")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            7D
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div className="cripto-box">
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Avaliação
          </div>
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("market_cap")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Market Cap
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("total_volume")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Volume/Mcap
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div
          className="cripto-text w-dropdown-toggle"
          onClick={() => sortCoins("dumpometro")}
        >
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Circ. Supply
          </div>
          <img
            src={caretDownOutline}
            loading="lazy"
            alt=""
            className="icon-xxsmall"
          />
        </div>

        <div className="cripto-box">
          <div className="text-size-small text-color-dark-gray text-style-allcaps">
            Fundamentos
          </div>
        </div>
        <div
          id="w-node-b4fa1d70-9614-8df0-fa6c-f84690b55f69-49152174"
          className="divisor"
        ></div>
      </div>
    </>
  );
};

export default MenuCrypto;
