import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";

import Footer from "../components/footer";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";

const mapState = (state) => ({
  modals: state.modals,
});


export const NotFound = ({ modals }) => {
  return (
    <>
      <div className="page-wrapper">
        <MetaTags>
          <title>Página não encontrada - Paradigma Education</title>
          <meta name="description" content="Página não encontrada - Paradigma Education" />
          <meta property="og:title" content="Página não encontrada" />
        </MetaTags>

        <ActionBar />
        <div className="main-wrapper page404">
          <div className="section_header">
            <HeaderMenu />
            <div className="page-padding">
              <div className="container-large padding-top padding-xxlarge">
                <div
                  className="padding-bottom padding-xxlarge"
                >
                  <div>
                    <h1 className="heading-xhuge">Página não encontrada 😬</h1>
                    <div className="text-size-large text-color-dark-gray margin-top margin-xsmall">
                      Que tal conhecer{" "}
                      <a
                        href="https://www.7diasdebitcoin.com.br/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-color-medium-green link-decoration"
                      >
                        nosso curso introdutório?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
};

export default connect(mapState)(NotFound);

