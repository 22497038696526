import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  openModal,
  modalType,
  landingPageModal,
} from "../store/actions/modals";
import MetaTags from "react-meta-tags";

import ActionBar from "../components/header/action-bar";
import Footer from "../components/footer";
import FearGreed from "../components/feargreed";
import CryptoTable from "../components/crypto-table";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";
import ModalChatBot from "../components/modal-chatbot";

import iconWhatsApp from "../images/icon-zap-home.svg";

const mapState = (state) => ({
  modals: state.modals,
});

export const Home = ({ modals, openModal, modalType, landingPageModal }) => {
  const [type] = useState("Pro");
  const [openModalChatBot, setOpenModalChatBot] = useState(false);

  useEffect(() => {
    if (openModal) {
      landingPageModal(false);
      modalType(type);
    }
  }, [modalType, type, openModal, landingPageModal]);

  return (
    <>
      <a
        href={`https://wa.me/${process.env.REACT_APP_COMPANY_CEL}?text=Olá,%20tudo%20bem?`}
        className="icon-whasapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Chat on WhatsApp" src={iconWhatsApp} />
      </a>
      <div className="page-wrapper">
        <MetaTags>
          <title>Paradigma Education</title>
          <meta
            name="description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
          <meta
            property="og:title"
            content="A vanguarda do conhecimento em Bitcoin & Criptomoedas"
          />
          <meta
            property="og:description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header">
            <HeaderMenu current="home" />
            <div className="page-padding">
              <div className="container-large">
                <div className="padding-vertical padding-xxlarge">
                  <div className="main-grid">
                    <div
                      id="w-node-e6935c6f-3638-e03f-08c8-ba4e4f695437-49152174"
                      className="grid-wrapper"
                    >
                      <div>
                        <h1 className="heading-xhuge">
                          As Maiores Criptomoedas
                        </h1>
                      </div>
                      <div className="margin-vertical margin-xsmall">
                        <div className="text-size-large text-color-dark-gray">
                          Compare preços lado a lado com fundamentos. Vire{" "}
                          <Link
                            to="/pro"
                            className="link-paradigma-pro text-color-medium-green link-decoration"
                          >
                            Paradigma Pro
                          </Link>{" "}
                          para desbloquear o melhor conteúdo.
                        </div>
                      </div>
                      <div
                        id="w-node-c3264975-3374-429e-8820-9bc466de5dcb-66de5db1"
                        className="buttons-wrapper buttons-wrapper-mobile"
                      >
                        <div
                          data-w-id="1990aa08-d2dc-abe4-1a3e-d3bd0365af24"
                          className="button-large w-inline-block"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            openModal(!modals.isOpened);
                          }}
                        >
                          <div>Vire Pro</div>
                        </div>
                        <Link to="/pro" className="link-decoration">
                          <div
                            data-w-id="acdeeb6f-de12-45ec-189e-523e92d15769"
                            className="button-large-secondary w-inline-block"
                          >
                            <div>Saiba mais</div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div
                      id="w-node-f99acad8-fa20-800d-5f76-178139bfb243-49152174"
                      className="grid-wrapper"
                    >
                      <div className="background-color-white style-radius padding-small">
                        <div>
                          <h2 className="heading-medium margin-bottom margin-small">
                            Índice do Medo &amp; Ganância
                          </h2>
                        </div>
                        <div className="grid-index-wrapper">
                          <div className="text-size-small text-style-allcaps text-color-red">
                            Medo Extremo
                          </div>
                          <div className="text-size-small text-style-allcaps text-color-dark-gray">
                            nível
                          </div>
                          <div className="text-size-small text-style-allcaps text-color-olive-green">
                            Euforia Extrema
                          </div>
                        </div>
                        <FearGreed />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CryptoTable />
          <Footer />
        </div>
        {openModalChatBot && (
          <ModalChatBot
            openModalChatBot={openModalChatBot}
            setOpenModalChatBot={setOpenModalChatBot}
          />
        )}
        {modals.isOpened && <Modals />}
      </div>
    </>
  );
};

export default connect(mapState, {
  openModal,
  modalType,
  landingPageModal,
})(Home);
