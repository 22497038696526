import React, { useEffect, useState } from "react";

import Article from "./article";
import Loading from "../../loading";
import api from "../../../services/global/api-feargreed";

const Media = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get("/api/v2/website/news")
      .then((response) => {
        setNews(
          response.data.news.map((el) => {
            return <Article title={el.title} link={el.link} key={el.id} />;
          })
        );
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [news.length]);

  return (
    <>
      <div className="padding-vertical padding-huge">
        <div className="flex-horizontal justify mobile">
          <div className="max-width-small">
            <h2 className="heading-xlarge margin-top margin-small">Na mídia</h2>
            <div className="text-size-large text-color-gray margin-top margin-small">
              Veja onde nosso trabalho vem aparecendo.
            </div>
          </div>
          <div className="max-width-xxlarge">
            {isLoading ? <Loading /> : null}
            {news.length ? news : []}
          </div>
        </div>
      </div>
    </>
  );
};

export default Media;
