import React from "react";
import MaskedInput from "react-text-mask";

export const TextMaskCardCPF = (props) => {
  const { inputRef = () => {}, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={(rawValue) => {
        const value = rawValue.replace(/(\.|-|\/)/g, "").trim();

        if (value.length > 11) {
          return [
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            "/",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ];
        } else {
          return [
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            ".",
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
          ];
        }
      }}
      placeholderChar={"\u2000"}
    />
  );
};
