import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { sub, format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import api from "../services/global/api-feargreed";

import Loading from "../components/loading";
import PreSalesForm from "../components/presales-form";
import Footer from "../components/footer";
import logoIcon from "../images/logo-paradigma.svg";

import "../styles/landing-tracks.css";
import "../styles/pre-sales.css";

export const PreSales = () => {
  const { idTrilhas } = useParams();
  const [dataTrail, setDataTrail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [formattedLaunchDay, setFormattedLaunchDay] = useState("");
  const getHeroThumbnail = () => {
    const style = {
      background:
        "linear-gradient(180deg,rgba(0, 0, 0, 0.24) 0%,rgba(0, 0, 0, 0) 50%),linear-gradient(90deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 50%),linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)),url(" +
        dataTrail.thumbnail +
        ") center no-repeat",
      backgroundSize: "cover",
      // width: "100vw",
      minHeight: "100vh",
      overflowX: "hidden",
    };
    return style;
  };

  useEffect(() => {
    async function getData() {
      try {
        const data = await (
          await api.get(`api/v2/video-tracks?includeDraft=true|false`)
        ).data.data;
        if (data) {
          const videoTrack = data.filter((el) => el.slug === idTrilhas)[0];
          setDataTrail(videoTrack);
        }
      } catch (e) {
        console.log(e);
      }
    }

    const all = async () => {
      await getData();
      setLoading(false);
    };
    all();
  }, [idTrilhas]);

  useEffect(() => {
    if (dataTrail && dataTrail.launchDay) {
      const launchDay = parseISO(dataTrail.launchDay);
      const startDate = sub(launchDay, { days: 7 });
      const endDate = launchDay;

      let formattedStartDate = format(startDate, "d", { locale: ptBR });
      let formattedEndDate = format(endDate, "d 'de' MMMM", { locale: ptBR });

      if (startDate.getMonth() !== endDate.getMonth()) {
        formattedStartDate += format(startDate, "' de' MMMM", { locale: ptBR });
      }

      const result = `${formattedStartDate} a ${formattedEndDate}`;
      setFormattedLaunchDay(result);
    }
  }, [dataTrail]);

  if (loading) {
    return (
      <>
        <div className="container-loading-trilhas">
          <Loading trilhas={true} />
        </div>
      </>
    );
  }
  return (
    dataTrail && (
      <>
        <div className="page-presales page-wrapper">
          <div className="main-wrapper">
            <div
              className="main-wrapper presales-hero"
              style={getHeroThumbnail()}
            >
              <div className="page-padding">
                <div className="container-large">
                  <div className="presales-info background-color-white style-radius">
                    <div className="presales-image">
                      <Link to="/" className="nav-image-logo w-nav-brand">
                        <img
                          src={logoIcon}
                          loading="lazy"
                          alt=""
                          className="image-logo"
                        />
                      </Link>
                    </div>

                    <div style={{ padding: "0px 16px" }}>
                      <p className="text-size-medium text-color-dark-gray presales-padding-small">
                        {formattedLaunchDay.toLocaleUpperCase()}
                      </p>
                      <p className="text-size-large text-color-dark-gray presales-padding-small">
                        {dataTrail?.preSales?.preSalesCopy}
                      </p>
                      <h1 className="heading-xhuge">{dataTrail.name}</h1>

                      <PreSalesForm
                        script={dataTrail?.preSales?.preSalesWhatsAppScript}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </>
    )
  );
};

export default PreSales;
