import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";

import Footer from "../components/footer";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";

import iconBitcoin from "../images/icon-bitcoin.svg";
import iconFive from "../images/icon-five.svg";

const mapState = (state) => ({
  modals: state.modals,
});

const ErrorPage = ({modals}) => {
  return (
    <>
      <div className="page-wrapper">
        <MetaTags>
          <title>Erro na página - Paradigma Education</title>
          <meta name="description" content="Erro na página - Paradigma Education" />
          <meta property="og:title" content="Erro na página" />
        </MetaTags>

        <ActionBar />
        <div className="main-wrapper page404">
          <div className="section_header">
            <HeaderMenu />
            <div className="page-padding">
              <div className="container-large padding-top padding-xxlarge">
                <div
                  className="padding-0"
                >
                  <div className="error-page-container">
                    <div className="icons-content">
                      <img src={iconFive} alt="icon five" />
                      <img src={iconBitcoin} alt="icon bitcoin" />
                      <img src={iconBitcoin} alt="icon bitcoin" />
                    </div>

                    <h1 className="heading-xhuge">Esta página está quebrada ou em manutenção.</h1>
                    <p className="text-size-large text-color-dark-gray margin-top margin-xsmall">Tente novamente mais tarde, ou peça ajuda pra algum humano.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
}

export default connect(mapState)(ErrorPage);
