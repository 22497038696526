import React from "react";
import { Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./styles/normalize.css";
import "./styles/webflow.css";
import "./styles/index.css";

import Home from "./pages/home";
// import HomeBlackFriday from "./pages/home-black-friday";
import BlackFriday from "./pages/black-friday";
import ParadigmaPro from "./pages/paradigma-pro";
import SobreNos from "./pages/sobre-nos";
import Ranking from "./pages/ranking";
import LandingVideoTracks from "./pages/landing-video-tracks";
import PreSales from "./pages/pre-sales";
import NotFound from "./pages/not-found";
import ErrorPage from "./pages/error-page";
import Payment from "./pages/payment";
import Vitalicio from "./pages/vitalicio";
// import VRC from "./pages/vrc";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        {/* <Route exact path="/vrc" element={<VRC />} /> */}
        <Route exact path="/black" element={<BlackFriday />} />
        {/* <Route exact path="/blackfriday" element={<HomeBlackFriday />} /> */}
        <Route exact path="/pagamento" element={<Payment />} />
        <Route exact path="/pro" element={<ParadigmaPro />} />
        <Route exact path="/paradigma-pro" element={<ParadigmaPro />} />
        <Route exact path="/sobre-nos" element={<SobreNos />} />
        <Route exact path="/ranking" element={<Ranking />} />
        <Route
          exact
          path="/trilhas/:idTrilhas"
          element={<LandingVideoTracks />}
        />
        <Route exact path="/vitalicio" element={<Vitalicio />} />
        <Route exact path="/livro" element={<Vitalicio />} />
        <Route exact path="/pre-sales/:idTrilhas" element={<PreSales />} />
        <Route exact path="/error-page" element={<ErrorPage />} />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
