import React from "react";

import "../../styles/landing-tracks.css";

// import testimonials from "../../../images/testimonials-screenshots@2x.png"

const Feedbacks = ({ usuarios }) => {
  return <>
    <div className="section-feedbacks">
      <div className="page-padding">
        <div className="container-large padding-vertical padding-top-bottom">
          <div className="align-center">
            <h2 className="landing-tracks-text-primary-x-large feedbacks-title">{`${usuarios.toLocaleString()} pessoas já lucraram com as ideias da Paradigma. A próxima história de sucesso pode ser você`}</h2>
          </div>
        </div>
      </div>
      <div className="flex-horizontal justify">
        <div className="column-image">
          <div className="cover-image cover-image-1 animation-image-column1"></div>
        </div>
        <div className="column-image column-image-2">
          <div className="cover-image cover-image-2 animation-image-column2"></div>
        </div>
        <div className="column-image column-image-3">
          <div className="cover-image cover-image-3 animation-image-column3"></div>
        </div>
        <div className="column-image column-image-4">
          <div className="cover-image cover-image-4 animation-image-column4"></div>
        </div>
      </div>
    </div>
  </>
}

export default Feedbacks;