import React, { useEffect, useState } from "react";
import api from "../../services/global/api-paradigma";
import ReactPaginate from "react-paginate";

import Crypto from "./crypto";
import Menu from "./menu";
import MenuCrypto from "./menu-crypto";
import HotCoins from "../hot-coins";

import loadingGif from "../../videos/loading.gif";
import searchoutline from "../../images/search-outline.svg";

const CryptoTable = () => {
  const [sliceStart] = useState(0);
  const [sliceEnd] = useState(50);
  const [coins, setCoins] = useState([]);
  const [symbol, setSymbol] = useState("$");
  const [currency, setCurrency] = useState("usd");
  const [isCurrencySelectorOpen, setIsCurrencySelectorOpen] = useState(false);
  const [isCategorySortOpen, setIsCategorySortOpen] = useState(false);
  const [coinGroups, setCoinGroups] = useState("$");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [isLinkOpen, setIsLinkOpen] = useState(false);
  const [linkOpenIndex, setLinkOpenIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [coinsWithPosts, setCoinsWithPosts] = useState({});
  const [pages, setPages] = useState(95);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [coinSearch, setCoinSearch] = useState([]);
  const [openHotCoins, setOpenHotCoins] = useState(false);

  useEffect(() => {
    async function getUsdCoins() {
      try {
        const response = await api.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=1&sparkline=false&price_change_percentage=1h,24h,7d");
        setCoins(response.data);
        window.localStorage.setItem("usdCoins", JSON.stringify(response.data));
        setPages(response.data.length);
      } catch (e) {
        try {
          setCoins(JSON.parse(window.localStorage.getItem("usdCoins")));
        } catch (e) {
          window.location.href = "/error-page";
        }
      }
    }

    async function getCoinCategory() {
      try {
        const response = await api.get("/api/v2/coins/categories");
        setCategories(response.data.categories);
        setCoinGroups(response.data.coins || []);
      } catch (e) {
        setCategories([]);
      }
    }

    async function getCoinsWithPosts() {
      try {
        const response = await api.get("/api/v2/post-with-coins");
        setCoinsWithPosts(
          response.data.coinsWithPosts
            ? response.data.coinsWithPosts
            : response.data.data.coinsWithPosts
        );
      } catch (e) {
        setCoinsWithPosts([]);
      }
    }

    const all = async () => {
      setLoading(true);
      await getUsdCoins();
      await getCoinCategory();
      await getCoinsWithPosts();
      setLoading(false);
    };
    all();
  }, []);

  const handleCategorySelect = (name) => {
    const filteredCoins = [];
    setLoading(true);
    setSelectedCategory(name);
    for (const key of Object.keys(coinGroups)) {
        if (coinGroups[key].categories) {
            if (coinGroups[key].categories.includes(name)) {
                const selectedCoin = coins.filter((coin) => coin.id === key);
                if (selectedCoin.length > 0) {
                    filteredCoins.push(selectedCoin[0]);
                }
            }
        }
    }

    if (filteredCoins.length > 0) {
      setCoins(filteredCoins);
      setIsCategorySortOpen(false);
      setLoading(false);
      return;
    }

    if (filteredCoins.length === 0) {
      setSelectedCategory("");
      setCoins(JSON.parse(window.localStorage.getItem("usdCoins")));
    }

    setLoading(false);
  };

  const handleLinkOpen = (indexOpen) => {
    setLinkOpenIndex(indexOpen);
    setIsLinkOpen(!isLinkOpen);
  };

  const openCurrencySelector = () => {
    setIsCurrencySelectorOpen(!isCurrencySelectorOpen);
  };

  const openCategorySort = () => {
    setIsCategorySortOpen(!isCategorySortOpen);
  };

  const selectCurrency = async (currency, symboll) => {
    setCurrency(currency);
    setSymbol(symboll);
    setIsCurrencySelectorOpen(false);
    setLoading(true);
    setLoading(false);
  };

  const handlePageChange = async (event) => {
    setCurrentPage(event.selected);
    setLoading(true);
    try {
        const response = await api.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=${event.selected+1}&sparkline=false&price_change_percentage=1h,24h,7d`);
        setCoins(response.data);
        window.localStorage.setItem(`usdCoins_${event.selected}`, JSON.stringify(response.data));
        setPages(response.data.length);
    } catch (err) {
        setCoins(JSON.parse(window.localStorage.getItem(`usdCoins_${event.selected}`)));
        setPages(JSON.parse(window.localStorage.getItem(`usdCoins_${event.selected}`)).length);
    }
    setLoading(false);
  };

  const handleTextChange = (e) => {
    setSearchValue(e.target.value.toLowerCase());
    searchCoins();
  };

  const searchCoins = () => {
    return coinSearch.filter((coin) =>
      coin.symbol
        .toLowerCase()
        .startsWith(searchValue.slice(0, Math.max(coin.symbol.length - 1, 1)))
    );
  };

  const handleSearch = (e) => {
    if (searchValue && e.key === "Enter") {
      e.preventDefault();
      searchCoins();
    }
  };

  const handleInputFocus = () => {
    setOpenHotCoins(true);
  };

  const handlePageSearch = async (rank, id) => {
    const itemPerPage = 50;

    let page = Number(rank) / itemPerPage;
    let pageCurrent = Math.ceil(page);

    setLoading(true);
    const result =  await api.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=${pageCurrent}&sparkline=false&price_change_percentage=1h,24h,7d`);
    window.localStorage.setItem(`usdCoins_${pageCurrent}`, JSON.stringify(result.data));
    const coins = result.data;
    const filteredCoins = coins.filter((coin) => coin.id === id);
    setCoins(filteredCoins);
    setLoading(false);
  };

  const selectOriginalOrder = async (currency, symboll) => {
    setCurrentPage(0);
    setCurrency(currency);
    setSymbol(symboll);
    setLoading(true);
    try {
      await setCurrentPage(0);
      const result =  await api.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=50&page=${currentPage}&sparkline=false&price_change_percentage=1h,24h,7d`);
      window.localStorage.setItem(`usdCoins_${currentPage}`, JSON.stringify(result.data));
      setCoins(result.data);
      setSearchValue("");
      setSelectedCategory("");
      setLoading(false);
    } catch (err) {
      setCoins(JSON.parse(window.localStorage.getItem(`usdCoins_${0}`)));
      setSearchValue("");
      setSelectedCategory("");
      setLoading(false);
    }
  };

  return (
    <>
      <div className="section">
        <div className="page-padding">
          <div className="container-large padding-bottom padding-xxlarge">
            <div className="criptotable-wrapper">
              {loading ||
              (coins !== null && coins !== undefined && coins.length < 1) ? (
                <div className="loader-main-container">
                  <img
                    className="loader-main"
                    src={loadingGif}
                    alt="loading..."
                  />
                </div>
              ) : (
                <>
                  <div className="criptotable-row margin-bottom margin-xsmall">
                    <Menu
                      coins={coins}
                      openCurrencySelector={openCurrencySelector}
                      selectCurrency={selectCurrency}
                      currency={currency}
                      isCurrencySelectorOpen={isCurrencySelectorOpen}
                      categories={categories}
                      openCategorySort={openCategorySort}
                      handleCategorySelect={handleCategorySelect}
                      isCategorySortOpen={isCategorySortOpen}
                      selectOriginalOrder={selectOriginalOrder}
                    />
                    <div style={{ position: "relative" }}>
                      <form className="search w-form">
                        <input
                          type="search"
                          className="search-input w-input"
                          maxLength="256"
                          name="query"
                          placeholder="Busca"
                          id="search"
                          autoComplete="off"
                          onKeyPress={handleSearch}
                          value={searchValue}
                          onChange={handleTextChange}
                          onClick={handleInputFocus}
                        />
                        <img
                          src={searchoutline}
                          loading="lazy"
                          alt=""
                          className="search-icon-absoluto"
                        />
                        <input
                          type="submit"
                          value="Search"
                          className="search-button w-button"
                        />
                      </form>
                      <HotCoins
                        openHotCoins={openHotCoins}
                        setOpenHotCoins={setOpenHotCoins}
                        coinSearch={coinSearch}
                        setCoinSearch={setCoinSearch}
                        searchValue={searchValue}
                        handlePageSearch={handlePageSearch}
                      />
                    </div>
                  </div>
                  <div className="criptotable-search-container">
                    <MenuCrypto coins={coins} setCoins={setCoins} />
                    {coins &&
                      coins.length >= 1 &&
                      coins.slice(sliceStart, sliceEnd).map((coin, index) => {
                        return (
                          <Crypto
                            index={index}
                            key={index}
                            marketRank={coin.market_cap_rank}
                            image={coin.image}
                            symbol={coin.symbol}
                            symboll={symbol}
                            name={coin.name}
                            currency={currency}
                            currentPrice={coin.current_price}
                            priceChange1h={
                              coin.price_change_percentage_1h_in_currency
                            }
                            priceChange24h={
                              coin.price_change_percentage_24h_in_currency
                            }
                            priceChange7d={
                              coin.price_change_percentage_7d_in_currency
                            }
                            marketCap={coin.market_cap}
                            volume={coin.total_volume}
                            fullyDilutedValuation={coin.fully_diluted_valuation}
                            coinGroups={coinGroups}
                            selectedCategory={selectedCategory}
                            coinId={coin.id}
                            handleLinkOpen={handleLinkOpen}
                            isLinkOpen={isLinkOpen}
                            linkOpenIndex={linkOpenIndex}
                            coinsWithPosts={coinsWithPosts}
                            star={coinGroups[coin.id]}
                          />
                        );
                      })}
                    <ReactPaginate
                      breakLabel="..."
                      previousLabel="<"
                      nextLabel=">"
                      forcePage={currentPage}
                      onPageChange={handlePageChange}
                      pageRangeDisplayed={5}
                      pageCount={pages}
                      renderOnZeroPageCount={null}
                      containerClassName={
                        searchValue || selectedCategory
                          ? "pagination-wrapper-display-none"
                          : "pagination-wrapper margin-vertical margin-small"
                      }
                      pageClassName="pagination-component w-inline-block"
                      pageLinkClassName="text-size-regular text-color-dark-gray"
                      nextLinkClassName="text-size-regular text-color-dark-gray"
                      previousLinkClassName="text-size-regular text-color-dark-gray"
                      previousClassName="pagination-component w-inline-block"
                      nextClassName="pagination-component w-inline-block"
                      breakClassName="pagination-component w-inline-block"
                      breakLinkClassName="text-size-regular text-color-dark-gray"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CryptoTable;
