import React from "react";
import MaskedInput from "react-text-mask";

export const TextMaskCardCvc = (props) => {
  const { inputRef = () => {}, mask, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
    />
  );
};
