import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { openModal, modalType, landingPageModal } from "../../store/actions/modals";
import Modals from "../modals";
import { decodeObjFromCookie } from "../../utils/cookie";

// import avatarAuthor from "../../images/author-avatar@2x.png";
import avatar05 from "../../images/user-avatar-05@2x.png";
import avatar04 from "../../images/user-avatar-04@2x.png";
import avatar03 from "../../images/user-avatar-03@2x.png";
import avatar02 from "../../images/user-avatar-02@2x.png";
import avatar01 from "../../images/user-avatar-01@2x.png";

import "../../styles/landing-tracks.css";

const mapState = (state) => ({
  modals: state.modals,
});

const InfoTrack = ({
  data,
  usuarios,
  resultRef,
  modals,
  openModal,
  modalType,
  landingPageModal,
}) => {
  const userSession = decodeObjFromCookie("_session");
  const [type] = useState("Pro");

  useEffect(() => {
    if (openModal) {
      landingPageModal(true);
      modalType(type);
    }
  }, [modalType, type, openModal, landingPageModal]);

  const handleClick = () => {
    resultRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const goToVideoTrack = () => {
    window.open(
      `https://app.paradigma.education/trilhas/${data.slug}?id=${data.id}`
    );
  };

  return (
    <>
      <div className="page-padding">
        <div className="container-large padding-vertical padding-huge ">
          <div className="main-grid page-ranking">
            <div
              id="w-node-_9fe109cd-84dd-4ee2-b0fe-8a75bc8bc482-35152179"
              className="grid-wrapper padding-bottom padding-xlarge section-info-track"
            >
              <div className="users-counter">
                <img src={avatar01} alt="" className="user-avatar" />
                <img src={avatar02} alt="" className="user-avatar" />
                <img src={avatar03} alt="" className="user-avatar" />
                <img src={avatar04} alt="" className="user-avatar" />
                <img src={avatar05} alt="" className="user-avatar" />
                <div className="landing-tracks-text-primary-x-small counter">
                  +{usuarios.toLocaleString()}
                </div>
              </div>
              <div className="container-info-track">
                <div className="landing-tracks-text-primary-xx-large info-track-title">
                  {data.name}
                </div>
                <p className="landing-tracks-text-primary-small info-track-text">
                  {data.description}
                </p>
                <div className="author">
                  {/* <img src={avatarAuthor} alt="" className="author-avatar" /> */}
                  <p
                    className="landing-tracks-text-primary-xx-small"
                    style={{ marginBottom: "0" }}
                  >
                    Ensinado por {data.producer}
                  </p>
                </div>
              </div>
              <div className="container-buttons">
                {!userSession ? (
                  <button
                    className="landing-tracks-text-primary-x-small button-iniciar"
                    onClick={() => openModal(!modals.isOpened)}
                  >
                    Comprar Acesso
                  </button>
                ) : (
                  <button
                    className="landing-tracks-text-primary-x-small button-iniciar"
                    onClick={goToVideoTrack}
                  >
                    Iniciar
                  </button>
                )}
                {data &&
                data.name.length > 0 &&
                data.introVideo.name.length > 0 ? (
                  <button
                    className="landing-tracks-text-primary-x-small button-trailer"
                    onClick={handleClick}
                  >
                    Trailer
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {modals.isOpened && <Modals />}
      </div>
    </>
  );
};

export default connect(mapState, { openModal, modalType, landingPageModal })(InfoTrack);
