import React, { useState, useEffect, useRef } from "react";

import close from "../../images/close-outline-1.svg";
import loadingGif from "../../images/Ellipsis.gif";
import iconLink from "../../images/icon-link.png";
import iconCircle from "../../images/icon-circle.png";
import iconBot from "../../images/icon-bot.png";

import api from "../../services/global/api-bot";

const QuestionAnswer = ({ question, response, loading }) => {
  const initialSources = response;
  const sources =
    initialSources && initialSources.metadata
      ? [
          ...new Map(
            initialSources.metadata.map((item) => [item["id"], item])
          ).values(),
        ]
      : [];
  const [showAllSources, setShowAllSources] = useState(false);

  const handleShowMore = () => {
    setShowAllSources(true);
  };

  const handleShowLess = () => {
    setShowAllSources(false);
  };

  return (
    <>
      <div className="modalchatbot-flex">
        <img src={iconCircle} alt="icon circle" className="modalchatbot-icon" />
        <p className="modalchatbot-text modalchatbot-question">{question}</p>
      </div>
      <div className="modalchatbot-flex">
        <img
          src={iconBot}
          alt="icon bot"
          className="modalchatbot-icon"
        />
        {loading ? (
          <img 
            src={loadingGif} 
            alt="loading" 
            className="modalchatbot-icon-margin"
          />
        ) : (
          <div className="modalchatbot-text modalchatbot-answer">
            <p
              className="modalchatbot-margin"
              dangerouslySetInnerHTML={{
                __html: response.response
                  .replaceAll(". ", ".<br /><br />")
                  .split("\n")
                  .join("<br />"),
              }}
            />
            {sources && sources.length > 0 ? (
              <div className="modalchatbot-source">
                <div className="title-source">Fonte: </div>
                <div className="link-source-container">
                  {sources.length > 0
                    ? sources.slice(0, 3).map((source, index) => (
                        <a
                          key={index}
                          href={source.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconLink} alt="icon link" />
                          {source.title || source.category.toUpperCase()}
                        </a>
                      ))
                    : null}
                  {!showAllSources && showAllSources && sources.length > 3 && (
                    <button onClick={handleShowMore}>+ ver mais</button>
                  )}
                  {showAllSources && sources.length > 3 && (
                    <>
                      {sources.length > 3 &&
                        sources.slice(3).map((source, index) => (
                          <a
                            key={index}
                            href={source.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={iconLink} alt="icon link" />
                            {source.title}
                          </a>
                        ))}
                      <button onClick={handleShowLess}>- ver menos</button>
                    </>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};

const ModalChatBot = ({ openModalChatBot, setOpenModalChatBot }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoadingQuestion, setIsLoadingQuestion] = useState(false);
  const [scrollToEnd, setScrollToEnd] = useState(false);
  const chatbotRef = useRef(null);

  useEffect(() => {
    if (scrollToEnd) {
      chatbotRef.current.scrollTop = chatbotRef.current.scrollHeight;
      setScrollToEnd(false);
    }
  }, [scrollToEnd]);

  const handleSendQuestion = async (e) => {
    e.preventDefault();
    const questionInput = e.target.elements.question;
    const question = questionInput.value;
    questionInput.value = "";
    const newQuestion = { question, response: null, loading: true };
    setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    setIsLoadingQuestion(true); //desabilita o botão
    setScrollToEnd(true);
    const history = `${questions
      .map((el) => {
        return `BOT:\n${el.response.response}\nParadigmer:\n${el.question}\n`;
      })
      .join("\n")}`;
    const response = await api.get(`/ask?query=${question}&history=${history}`);
    setIsLoadingQuestion(false); //habilita o botão
    newQuestion.response = response.data;
    newQuestion.loading = false;
    setQuestions((prevQuestions) => [...prevQuestions]);
    setScrollToEnd(true);
  };

  return (
    <>
      <div className="modalchatbot">
        <div className="modalchatbot-content">
          <img
            src={close}
            alt="close"
            className="closebot"
            onClick={() => setOpenModalChatBot(!openModalChatBot)}
          />
          <form className="modalchatbot-form" onSubmit={handleSendQuestion}>
            <input
              type="search"
              name="question"
              className="modalchatbot-input"
              autoComplete="off"
              placeholder="Pergunte qualquer coisa sobre cripto"
            />
            <button
              className="modalchatbot-button"
              disabled={isLoadingQuestion}
            >
              <img src={iconBot} alt="icon bot" className="modalchatbot-icon" />
            </button>
          </form>
          <div className="modalchatbot-questionAnswer" ref={chatbotRef}>
            {questions.map((q, i) => (
              <QuestionAnswer
                key={i}
                question={q.question}
                response={q.response}
                loading={q.loading}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalChatBot;
