import React from "react";
import { forwardRef } from "react";

import "../../styles/landing-tracks.css";

// import iconPlay from '../../../images/icon-play-96px.svg';
// import trailer from '../../../images/trailer-preview-image@2x.png';
import iconMaterials from "../../images/icon-materials-32px.svg";
import iconExperts from "../../images/icon-experts-32px.svg";
import iconDidatic from "../../images/icon-didatic-32px.svg";

const VideoTrailer = forwardRef(({ data }, ref) => {
  return (
    <>
      <div className="section-video-trailer">
        <div className="page-padding">
          <div className="container-large padding-top-bottom">
            <div className="main-grid paradigma-video-trailer">
              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconDidatic} alt="icon-didatic" />
                </div>
                <div className="card-title">Didática Inesquecível</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Aprenda de forma simples, prática e inesquecível.
                </p>
              </div>

              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconExperts} alt="icon-experts" />
                </div>
                <div className="card-title">Aprenda com os Melhores</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Aulas exclusivas com os maiores experts do mercado.
                </p>
              </div>

              <div className="card">
                <div
                  style={{
                    width: "30px",
                    height: "30px",
                    margin: "68px 0 24px 0",
                  }}
                >
                  <img src={iconMaterials} alt="icon-materials" />
                </div>
                <div className="card-title">Materiais Complementares</div>
                <p className="landing-tracks-text-second-small card-text-size">
                  Além das videoaulas, você também terá ebooks, infográficos e
                  muito mais.
                </p>
              </div>
            </div>
            <div className="">
              <div className="video-container" ref={ref}>
                <div className="landing-tracks-text-primary-x-large title-container">
                  Assista ao trailer
                </div>

                <div className="video-trailer">
                  <video
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                    data-wf-ignore="true"
                    data-object-fit="cover"
                  >
                    <source src={data} data-wf-ignore="true" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default VideoTrailer;
