import React, { useState, useEffect } from "react";

import checkmark from "../../../images/checkmark-outline-2.svg";
import moedaPro from "../../../images/icon-moeda.svg";
import moedaLight from "../../../images/coin-light.png";

const CheckMark = (props) => {
  return (
    <>
      <div className="flex-horizontal margin-bottom margin-xxsmall">
        <img
          src={checkmark}
          loading="lazy"
          alt=""
          style={{ objectFit: "contain" }}
        />
        <div className="text-size-medium text-color-dark-gray margin-left margin-xxsmall">
          {`${props.miniCursos ? props.miniCursos : ""} ${props.text}`}
        </div>
      </div>
    </>
  );
};

const ModalMain = ({
  modals,
  setPrice,
  setPPlan,
  setPeriod,
  setOldPrice,
  modalType,
}) => {
  const [priceAnual, setPriceAnual] = useState(0);
  const [frequency, setFrequency] = useState(12);
  const [type, setType] = useState("Pro");

  useEffect(() => {
    if (modals.landingPageModal) modalType(type);
  }, [modals.landingPageModal, modalType, type]);

  useEffect(() => {
    if (modals.modalType === "Light") {
      setPriceAnual(598.8);
      const getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
      setPrice(getPrice());
      setOldPrice(598.8);
      setFrequency(12);
      setPeriod(true);
      setPPlan(3);
    } else {
      setPriceAnual(1438.8);
      setOldPrice(1438.8);
      const getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
      setPrice(getPrice());
      setFrequency(12);
      setPeriod(true);
      setPPlan(5);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setPeriod,
    priceAnual,
    modals.discount,
    modals.modalType,
    modals.period,
    setPrice,
    setOldPrice,
  ]);

  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const calculateFrequencty = () => {
    let getPrice;
    if (modals.modalType === "Light") {
      getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
    } else {
      getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
    }
    return formatter.format(getPrice() / frequency);
  };

  // const onClickSwitcher = () => {
  //   setSwitcher(!switcher);
  //   if (switcher) {
  //     setOldPrice(priceQuadri);
  //     setPPlan(4);
  //     setFrequency(4);
  //     setPrice(
  //       modals.discount
  //         ? priceQuadri - (priceQuadri * modals.discount) / 100
  //         : priceQuadri
  //     );
  //   }
  //   if (modals.period) {
  //     setPeriod(!modals.period);
  //     if (modals.modalType === "Light") {
  //       setPPlan(2);
  //     } else {
  //       setPPlan(4);
  //     }
  //   } else {
  //     setFrequency(12);
  //     setPeriod(!modals.period);
  //     if (modals.modalType === "Light") {
  //       setPPlan(3);
  //     } else {
  //       setPPlan(5);
  //     }
  //     setPrice(
  //       modals.discount
  //         ? priceAnual - (priceAnual * modals.discount) / 100
  //         : priceAnual
  //     );
  //   }
  // };

  const onChangeModalType = (e) => {
    setType(e.target.value);
    modalType(e.target.value);

    if (e.target.value === "Light") {
      setPPlan(3);
    } else {
      setPPlan(5);
    }
  };

  return (
    <>
      <div className="flex-horizontal-center">
        {/* <div className="text-size-medium text-font-manrope text-color-dark-gray">
          Quadrimestral
        </div> */}
        {/* <div
          className="selector-button margin-horizontal margin-xxsmall"
          onClick={onClickSwitcher}
        >
          <div
            className={
              !modals.period ? "selector-ball-quadri" : "selector-ball-anual"
            }
          ></div>
        </div> */}
        {/* <div className="text-size-medium text-font-manrope text-color-dark-gray">
          Anual
        </div>
        <div className="flex-number size-small">
          <div className="text-size-small text-color-white">25% OFF</div>
        </div> */}
      </div>
      <div className="flex-horizontal justify margin-vertical margin-small">
        {!modals.landingPageModal ? (
          <h2 className="heading-xlarge heading-xlarge-modals">
            {modals.modalType === "Light" ? "Paradigma Light" : "Paradigma Pro"}
          </h2>
        ) : (
          <select
            className="landing-page-select-modals"
            name="mdoalType"
            id="modalType"
            onChange={(e) => onChangeModalType(e)}
          >
            <option value="Pro">Paradigma Pro</option>
            <option value="Light">Paradigma Light</option>
          </select>
        )}
        <div>
          {modals.discount !== undefined ? (
            <div
              className="text-size-xxlarge"
              style={{
                textDecoration: "line-through",
                display: modals.oldPrice,
              }}
            >
              {formatter.format(modals.oldPrice)}
            </div>
          ) : null}
          <div className="flex-horizontal-center flex-horizontal-end">
            <div className="text-size-xxlarge" style={{ fontSize: "18px" }}>
              {formatter.format(modals.price)}
            </div>
          </div>
          <div className="text-size-regular text-font-manrope text-color-dark-gray text-align-right">
            (equivale {calculateFrequencty()}
            /mês)
          </div>
          {modals.discount !== undefined ? (
            <>
              <div
                className="text-size-regular text-font-manrope text-color-dark-gray text-align-right"
                style={{ display: modals.price }}
              >
                {`Cupom de ${modals.discount}% aplicado.`}
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="divisor margin-vertical margin-xsmall"></div>
      <div className="duo-grid margin-bottom">
        <div>
          <div
            id="w-node-_1f98e407-54c1-8087-30a4-f4672b2eca2e-78ec268d"
            className="text-size-small text-color-dark-gray margin-bottom margin-xsmall text-style-allcaps"
          >
            Incluso:
          </div>
          {modals.modalType === "Light" ? (
            <>
              <CheckMark text="Todos nossos Mini-Cursos" />
              <CheckMark text="Boletim Semanal toda Quinta" />
              <CheckMark text="Manual de Uso do Bitcoin" />
              <CheckMark text="1 Ferramenta de Valuation" />
            </>
          ) : (
            <>
              {/* <CheckMark text="Carteiras simuladas" />
              <CheckMark text="+20 indicadores exclusivos" />
              <CheckMark text="Chat direto com analistas, 24/7" />
              <CheckMark text="Boletins, relatórios e cursos" /> */}

              <CheckMark text="6 mini-cursos: uma formação completa" />
              <CheckMark text="Todas nossas carteiras recomendadas" />
              <CheckMark text="Acompanhamento semanal" />
              <CheckMark text="Relatórios temáticos premium" />
              <CheckMark text="Comunidade exclusiva e suporte técnico" />
              <CheckMark text="Ideias de trade degeneradas" />
            </>
          )}
        </div>
        <img
          src={modals.modalType === "Light" ? moedaLight : moedaPro}
          loading="lazy"
          id="w-node-_5cf54861-e87c-f46c-deca-19c064730eb6-78ec268d"
          alt=""
          className="icon-money"
        />
      </div>
    </>
  );
};

export default ModalMain;
