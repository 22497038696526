import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  openModal,
  modalType,
  landingPageModal,
} from "../store/actions/modals";
import MetaTags from "react-meta-tags";

import ActionBar from "../components/header/action-bar";
import Footer from "../components/footer";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";

const mapState = (state) => ({
  modals: state.modals,
});

export const Payment = ({ modalType, landingPageModal }) => {
  useEffect(() => {
    landingPageModal(false);
    modalType("pro");
  }, [modalType, landingPageModal]);

  return (
    <>
      <div className="page-wrapper">
        <MetaTags>
          <title>Paradigma Education</title>
          <meta
            name="description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
          <meta
            property="og:title"
            content="A vanguarda do conhecimento em Bitcoin & Criptomoedas"
          />
          <meta
            property="og:description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header">
            <HeaderMenu current="" />
            <div className="page-padding">
              <div className="container-large">
                <div className="padding-vertical padding-xxlarge">
                  <Modals />
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default connect(mapState, {
  openModal,
  modalType,
  landingPageModal,
})(Payment);
