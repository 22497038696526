import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import {
  openModal,
  modalType,
  landingPageModal,
} from "../../store/actions/modals";
import Modals from "../modals";

// import "../../../styles/landing-tracks.css";

const mapState = (state) => ({
  modals: state.modals,
});

const BuyAccess = ({
  data,
  modals,
  openModal,
  modalType,
  landingPageModal,
}) => {
  const [type] = useState("Pro");

  useEffect(() => {
    if (openModal) {
      landingPageModal(true);
      modalType(type);
    }
  }, [modalType, type, openModal, landingPageModal]);

  return (
    <>
      <div className="section-buy-access">
        <div className="page-padding">
          <div className="container-large padding-xlarge">
            <div className="margin-vertical margin-xlarge container-buy-access">
              <div className="track-max-width card-mobile">
                <div className="landing-tracks-text-primary-x-large">
                  {data.modulesCount}
                </div>
                <div className="landing-tracks-text-primary-large">módulos</div>
                <div
                  style={{ width: "200px" }}
                  className="landing-tracks-text-second-small margin-top margin-small"
                >
                  {`Acesso a ${
                    data.modulesCount || 0
                  } módulos completos em sua conta`}
                </div>
              </div>

              <div className="track-max-width card-mobile track-card-mobile">
                <div className="landing-tracks-text-primary-x-large">
                  {data.videosCount || 0}
                </div>
                <div className="landing-tracks-text-primary-large">vídeos</div>
                <div
                  style={{ width: "200px", wordWrap: "break-word" }}
                  className="landing-tracks-text-second-small margin-top margin-small"
                >
                  {data?.videosHDuration > 1
                    ? `Totalizando mais de ${data?.videosHDuration} horas de conteúdo exclusivo`
                    : `Totalizando em ${data?.videosHDuration} hora de conteúdo exclusivo`}
                </div>
              </div>

              <div className="card-mobile track-max-width">
                <div
                  className="landing-tracks-text-primary-large"
                  style={{ paddingBottom: "16px" }}
                >
                  Comece agora
                </div>
                <button
                  style={{ marginBottom: "8px" }}
                  onClick={() => openModal(!modals.isOpened)}
                >
                  Comprar Acesso
                </button>
                {/* <p className="landing-tracks-text-second-x-small">
                  Pagamento único de R$ 639,60
                </p> */}
              </div>
            </div>
          </div>
        </div>
        {modals.isOpened && <Modals />}
      </div>
    </>
  );
};

export default connect(mapState, { openModal, modalType, landingPageModal })(
  BuyAccess
);
