import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import api from "../../services/global/api-feargreed";
import { setTracks } from "../../store/actions/tracks";

const mapState = (state) => ({
  tracks: state.tracks,
});

const TracksDropdown = ({ isOpenDropdown, setTracks }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        const response = await (
          await api.get(
            `api/v2/video-tracks?includeDraft=true|false&fields=name`
          )
        ).data.data;
        if (response) {
          setData(response);
          setTracks(response);
        }
      } catch (e) {
        console.error(e);
      }
    }
    getData();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {isOpenDropdown && (
        <nav className="menudropdown-tracks-link-container">
          {data.map((item) => (
            <a
              className="menudropdown-tracks-link"
              href={`/trilhas/${item.slug}`}
            >
              {item.name}
            </a>
          ))}
        </nav>
      )}
    </>
  );
};

export default connect(mapState, {
  setTracks,
})(TracksDropdown);
