import React, { useEffect, useState } from "react";
import moment from "moment";

import api from "../../services/global/api-feargreed";

import Item from "./item";
import Loading from "../loading";

const FearGreed = () => {
  const [index, setIndex] = useState([{}]);
  const [removeLoading, setRemoveLoading] = useState(false);
  const timestamp = index[0].timestamp;
  const value = index[0].value;
  const formatted = moment.unix(timestamp, "MM-DD-YYYY").format('DD/MMM');

  useEffect(() => {
    setTimeout(() => {
      async function getIndexFearGreed() {
        try {
          const response = await api.get("api/v2/indicators/fear-greed-index");
          setIndex(response.data.data.data);
          setRemoveLoading(true);
        } catch(e) {
          console.log(e);
        }
      }
      getIndexFearGreed();
    }, 3000)
  }, [])

  if (!removeLoading) {
    return <Loading/>
  } else {
    return <>
    <div>
      <div className="index-color margin-custom1">
        <Item number="0" value={value}/>  
        <Item number="1" value={value}/>  
        <Item number="2" value={value}/>  
        <Item number="3" value={value}/>  
        <Item number="4" value={value}/>  
        <Item number="5" value={value}/>    
        <Item number="6" value={value}/>  
        <Item number="7" value={value}/>  
        <Item number="8" value={value}/>  
        <Item number="9" value={value}/>  
        <Item number="10" value={value}/>  
        <Item number="11" value={value}/>  
        <Item number="12" value={value}/>  
        <Item number="13" value={value}/>  
        <Item number="14" value={value}/>  
        <Item number="15" value={value}/>  
        <Item number="16" value={value}/>  
        <Item number="17" value={value}/>  
        <Item number="18" value={value}/>  
        <Item number="19" value={value}/>  
        <Item number="20" value={value}/>  
        <Item number="21" value={value}/>  
        <Item number="22" value={value}/> 
        <Item number="23" value={value}/>
      </div>
      <div className="text-size-regular text-color-main-gray text-align-right">{`Último update em ${formatted}`}</div>
    </div>
    
  </>
  } 
}

export default FearGreed;