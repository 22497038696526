import React, { useState } from "react";
import "../../../styles/landing-tracks.css";
import Module from "./module/module";

const Modules = ({ data }) => {
  let [quantity, setQuantity] = useState(3);
  const increase = () => {
    setQuantity(quantity++);
  };

  return (
    <>
      <div className="section-modules">
        <div className="page-padding-tracks">
          <div className="container-large padding-top-bottom">
            {data &&
              data
                .slice(0, quantity)
                .map((item, index) => (
                  <Module key={index} module={item.name} videos={item.videos} slug={item.slug} />
                ))}
          </div>
          {quantity < data.length && (
            <button
              className="landing-tracks-text-primary-x-small modules-button"
              onClick={increase}
            >
              Ver mais
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Modules;
