import React, { useEffect } from "react";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";

import Footer from "../components/footer";
import Button from "../components/button";
import Indicators from "../components/paradigma-pro/indicators";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import ModalVRCForm from "../components/modals/modal-vrc-form";
import moeda from "../images/image-moeda.png";
import fire from "../images/icon-fire.png";
import imgHero from "../images/img-hero-paradigma-pro@2x.png";
import iconWhatsApp from "../images/icon-zap-home.svg";
import {
  openModal,
  modalType,
  setPeriod,
  setModal,
  setPrice,
  setInstallments,
  landingPageModal,
} from "../store/actions/modals";

const mapState = (state) => ({
  modals: state.modals,
});

export const BlackFriday = ({
  modals,
  openModal,
  modalType,
  setPeriod,
  setModal,
  landingPageModal,
}) => {
  useEffect(() => {
    if (modals.isOpened) {
      landingPageModal(false);
      modalType("pro");
    }
  }, [modals.isOpened, landingPageModal, modalType]);

  return (
    <>
      <a
        href={`https://wa.me/${process.env.REACT_APP_COMPANY_CEL}?text=Olá,%20tudo%20bem?`}
        className="icon-whasapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Chat on WhatsApp" src={iconWhatsApp} />
      </a>
      <div className="page-wrapper">
        <MetaTags>
          <title>2 anos por 1 na melhor research de cripto do Brasil</title>
          <meta
            name="description"
            content="Aproveite essa condição exclusiva pra alunos da turma VRC6. Nossa assinatura te mantém atualizado sobre as melhores oportunidades de investimento do mercado."
          />
          <meta
            property="og:title"
            content="2 anos por 1 na melhor research de cripto do Brasil"
          />
          <meta
            property="og:description"
            content="Aproveite essa condição exclusiva pra alunos da turma VRC6. Nossa assinatura te mantém atualizado sobre as melhores oportunidades de investimento do mercado."
          />
        </MetaTags>
        <ActionBar isBlackFriday />
        <div className="main-wrapper">
          <div className="section_header page-pro-background">
            <HeaderMenu current="pro" />
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge padding-sale-banner">
                <div className="main-grid grid-custom">
                  <div
                    id="w-node-_4758ae2d-3723-6356-40c0-4e8dd8087bb4-71152178"
                    className="grid-wrapper"
                  >
                    <div className="flex-horizontal-bottom">
                      <div className="text-size-xlarge">
                        <s>R$ 2877,60</s>
                      </div>
                      <div className="flex-number">
                        <div className="text-size-medium text-color-white text-weight-semibold">
                          R$ 1438,80
                        </div>
                      </div>
                    </div>
                    <div className="flex-horizontal-bottom max-width-xlarge margin-vertical margin-xsmall">
                      <div className="heading-xhuge">
                        <img
                          src={fire}
                          loading="lazy"
                          alt=""
                          className="emoji-fire"
                        />
                        2 anos por 1 na melhor research de cripto do Brasil
                      </div>
                      <img
                        src={moeda}
                        loading="lazy"
                        alt=""
                        className="emoji-money"
                      />
                    </div>
                    <div className="text-size-large text-color-dark-gray">
                      <span className="text-color-darkest-gray text-weight-medium">
                        A Cyber Week da Paradigma vai só até sexta.
                      </span>{" "}
                      Não é todo dia que o Bitcoin rompe máxima histórica e o
                      mercado oferece tanta oportunidade assim. Aproveite o
                      melhor conteúdo, suporte e orientação do mercado, enquanto
                      ainda dá tempo.
                    </div>
                    <div
                      className="buttons-wrapper margin-top margin-large"
                      onClick={() => {
                        openModal(!modals.isOpened);
                      }}
                    >
                      <Button
                        id="4758ae2d-3723-6356-40c0-4e8dd8087bbe"
                        namesClass="button-large w-inline-block"
                        title="ASSINE AGORA"
                      />
                    </div>
                  </div>
                  <div
                    id="w-node-_4758ae2d-3723-6356-40c0-4e8dd8087bc4-71152178"
                    className="grid-wrapper custom-inner"
                  >
                    <img
                      src={imgHero}
                      srcSet={imgHero}
                      loading="lazy"
                      id="w-node-_981e73c5-53a5-2281-123e-afec0277f944-71152178"
                      sizes="(max-width: 767px) 100vw, (max-width: 991px) 95vw, 816px"
                      alt=""
                      className="full-image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        
            <div className="sale-banner">
              <div className="sale-banner-text">
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
              </div>
            </div>
            <div className="sale-banner">
              <div className="sale-banner-text">
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
                O melhor desconto do ano: acaba nessa semana!
              </div>
            </div>
          
          <Indicators />
        </div>
        <Footer isBlackFriday />
      </div>
      {modals.isOpened && (
        <ModalVRCForm
          modals={modals}
          setPrice={setPrice}
          setInstallments={setInstallments}
        />
      )}
    </>
  );
};

export default connect(mapState, {
  openModal,
  modalType,
  setPeriod,
  setModal,
  landingPageModal,
})(BlackFriday);
