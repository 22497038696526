import React, { useState, useEffect } from "react";
import { parseISO, intervalToDuration } from "date-fns";
import ActionBar from "../action-bar";

const ActionBarTrack = ({ track }) => {
  const [isInThePast, setIsInThePast] = useState(false);
  const [duration, setDuration] = useState({});

  // const getDuration = () => {
  //   if (track !== null)
  //     return formatDistanceToNow(parseISO(track.launchDay), {
  //       addSuffix: true,
  //       includeSeconds: true,
  //       locale: ptBR,
  //     });
  // };

  useEffect(() => {
    const isInThePast = () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setIsInThePast(parseISO(track.launchDay) < today);
    };

    isInThePast();
  }, [track]);

  useEffect(() => {
    console.info("track", track.launchDay);
    if (track.launchDay && new Date(track.launchDay) >= new Date())
      setInterval(() => {
        setDuration(
          intervalToDuration({
            start: new Date(),
            end: new Date(track.launchDay),
          })
        );
      }, 1000);
  }, [track]);

  return (
    <>
      {isInThePast ? (
        <ActionBar isTrack={true} />
      ) : (
        <div className="stickbar-section stickbar-section-tracks">
          <div className="page-padding">
            <div className="text-size-medium text-color-white">
              {/* <span> */}
              {/*   ⏰ 72h 31m 15s | Compre até DD/MM e ganhe acompanhamento dedicado */}
              {/*   do professor */}
              {/* </span> */}
              <span>
                ⏰ Acaba em
                <span style={{ color: "#3de8a0" }}>
                  {`
                    ${duration.days || "0"}d 
                    ${duration.hours || "0"}h 
                    ${duration.minutes || "0"}m 
                    ${duration.seconds || "00"}s
                  `}
                </span>
                o lançamento da trilha
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionBarTrack;
