import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";

import api from "../services/global/api-feargreed";
import Footer from "../components/footer";
import ActionBarTrack from "../components/header/action-bar-video-tracks";
import HeaderMenu from "../components/header/menu";
import BuyAccess from "../components/landing-video-tracks/buy-access";
import CollectibleBadge from "../components/landing-video-tracks/collectible-badge";
import Modules from "../components/landing-video-tracks/modules/modules";
import Tracks from "../components/landing-video-tracks/tracks";
import VideoTrailer from "../components/landing-video-tracks/video-trailer";
import Discord from "../components/landing-video-tracks/discord";
import Feedbacks from "../components/landing-video-tracks/feedbacks";
import SignParadigma from "../components/landing-video-tracks/sign-paradigma";
import Satisfaction from "../components/landing-video-tracks/satisfaction";
import InfoTrack from "../components/landing-video-tracks/info-tracks";
import Loading from "../components/loading";
import iconWhatsApp from "../images/icon-zap-home.svg";
import { resizeImage } from "../utils";

import "../styles/landing-tracks.css";

const mapState = (state) => ({
  tracks: state.tracks,
});

export const LandingVideoTracks = ({ tracks }) => {
  const [loading, setLoading] = useState(true);
  const [dataTrail, setDataTrail] = useState(null);
  const [dataModules, setModules] = useState([]);
  const [usuarios, setUsuarios] = useState("7663");
  const resultRef = useRef(null);
  const { idTrilhas } = useParams();

  const getHeroThumbnail = () => {
    const style = {
      background:
        "linear-gradient(180deg,rgba(0, 0, 0, 0.24) 0%,rgba(0, 0, 0, 0) 50%),linear-gradient(90deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0) 50%),linear-gradient(0deg, rgba(0, 0, 0, 0.16), rgba(0, 0, 0, 0.16)),url(" +
        resizeImage(dataTrail.thumbnail, 0) +
        ") center no-repeat",
      backgroundSize: "cover",
    };
    return style;
  };

  useEffect(() => {
    async function getData() {
      try {
        const data = await (
          await api.get(`api/v2/video-track/${idTrilhas}`)
        ).data.data;
        if (data) {
          setDataTrail(data);
          setModules(data.modules);
        }
      } catch (e) {
        window.location.href = "/error-page";
      }
    }

    async function getTotalUsarios() {
      try {
        const response = await api.get("/api/v2/website/users-count");
        setUsuarios(response.data.count);
      } catch (e) {
        console.log(e);
      }
    }

    const all = async () => {
      await getData();
      await getTotalUsarios();
      setLoading(false);
    };
    all();
  }, [idTrilhas]);

  if (loading) {
    return (
      <>
        <div className="container-loading-trilhas">
          <Loading trilhas={true} />
        </div>
      </>
    );
  }
  return (
    dataTrail && (
      <>
        <a
          href={`https://wa.me/${process.env.REACT_APP_COMPANY_CEL}?text=Olá,%20tudo%20bem?`}
          className="icon-whasapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="Chat on WhatsApp" src={iconWhatsApp} />
        </a>
        <div className="page-wrapper landing-page-tracks">
          <MetaTags>
            <title>{dataTrail.name}</title>
            <meta name="description" content={dataTrail.description} />
            <meta property="og:title" content={dataTrail.name} />
            <meta property="og:description" content={dataTrail.description} />
          </MetaTags>
          {dataTrail !== undefined && <ActionBarTrack track={dataTrail} />}
          <div className="main-wrapper">
            <div
              className="main-wrapper page-video-track"
              style={getHeroThumbnail()}
            >
              <HeaderMenu current="tracks" />

              {dataTrail && (
                <InfoTrack
                  data={dataTrail}
                  usuarios={usuarios}
                  resultRef={resultRef}
                />
              )}
            </div>

            {dataTrail && <BuyAccess data={dataTrail} />}
            {dataTrail && <CollectibleBadge data={dataTrail} />}
            {dataTrail && dataModules.length > 0 && (
              <Modules data={dataModules} />
            )}
            {tracks.tracksData && (
              <Tracks
                data={tracks.tracksData
                  .filter((el) => el.slug !== idTrilhas)
                  .filter((el) => !el.draft)}
              />
            )}
            {dataTrail && dataTrail.introVideo.mp4Url.length > 0 ? (
              <VideoTrailer
                data={dataTrail.introVideo.mp4Url}
                ref={resultRef}
              />
            ) : null}
            <Discord />
            <Feedbacks usuarios={usuarios} />
            <SignParadigma />
            <Satisfaction />
          </div>

          <Footer />
        </div>
      </>
    )
  );
};

export default connect(mapState, {})(LandingVideoTracks);
