const initialState = {
  tracksData: [],
};

export const tracks = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRACKS":
      return {
        ...state,
        tracksData: action.payload,
      };
    default:
      return state;
  }
};
