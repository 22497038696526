import React from "react";
import "./index.css";

const Message = ({text, status}) => {
  return <>
    <div 
      className="message-container" 
      style={{
        backgroundColor: 
          status === "success" ? "#68ffbf" : 
          status === "error" ? "#F8D7DB" : 
          "#FFF3CD"
        }}
      >
      <span 
        className="message-text" 
        style={{
          color: 
          status === "success" ? "#126C46" : 
          status === "error" ? "#842029" : 
          "#D39E00"
        }}
      >
        {text}
      </span>
    </div>
  </>
}

export default Message;

// #842029
// #F8D7DB
// #68ffbf
// #126C46
// #FFF3CD 
// #D39E00