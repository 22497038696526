import React from "react";
import loadingGif from "../../videos/loading.gif";
import loadingTrilhas from "../../images/loading.gif";

const Loading = ({ trilhas }) => {
  return <>
  <div className={trilhas ? "loading-trilhas" : "loader"}>
    <img src={trilhas ? loadingTrilhas : loadingGif} alt="loading..." />
  </div> 
  </>
}

export default Loading;