import React, { useState, useEffect } from "react";
import axios from "axios";
import "./index.css";

import fireIcon from '../../images/search-table-trending-emoji.png';
import close from "../../images/close-outline-16px.svg";

const HotCoins = ({
  openHotCoins, 
  setOpenHotCoins, 
  coinSearch, 
  setCoinSearch,
  searchValue, 
  handlePageSearch
}) => {
  const [hotCoins, setHotCoins] = useState([]);
 
  useEffect(() => {
    axios
      .get("https://api.coingecko.com/api/v3/search/trending")
      .then(res => setHotCoins(res.data.coins))
      .catch(error => console.log(error))
  }, []);

  useEffect(() => {
    async function getCoinSearch() {
      try {
        if (searchValue && searchValue.trim() !== "") {
          const response = await axios.get(
            `https://api.coingecko.com/api/v3/search?query=${searchValue}`
          );
          setCoinSearch(response.data.coins);
        } else {
          setCoinSearch([]);
          return;
        }
      } catch (e) {
        console.log(e);
      }
    }

    getCoinSearch();
  }, [searchValue, setCoinSearch]);

  const closeHotCoin = () => {
    setOpenHotCoins(false)
  }

  return <>
    <div className="container-search-hot" 
      style={{
        display: openHotCoins ? "flex" : "none"
      }}>
      <div className="title-search-hot">
        <div className="title">
          <div className="title-icon" 
            style={{
              display: searchValue ? "none" : null
            }}>
            <img className="title-fire-icon" src={fireIcon} alt='Fire icon' />
          </div>
          <div>{searchValue ? "" : "Quentes"}</div>
          <img className="title-close-icon" src={close} alt='Close icon' onClick={() => closeHotCoin()}/>
        </div>
        <div className="divisor"></div>
      </div>
      {searchValue && searchValue.length > 0 ? 
        coinSearch && coinSearch.length > 0 
        ? coinSearch.slice(0, 6).map((coin, index) => {
          return <>
            <div key={index} className="item-search-hot" onClick={() => handlePageSearch(coin.market_cap_rank, coin.id)}>
              <div className="left-search-hot">
                <img src={coin.large} alt={coin.name}/>
                <span>{coin.name} ({coin.symbol})</span>
              </div>
              <div className="right-search-hot">
                <span>#{coin.market_cap_rank}</span>
              </div>
            </div>
          </>
          })
        : 
        <p 
          style={{
            textAlign: "center", 
            padding: "20px 0", 
            marginBottom: "0"
          }}
        >
          Moeda não encontrada.
        </p> 
      : hotCoins.map((coin, index) => {
          return <>
            <div key={index} className="item-search-hot">
              <div className="left-search-hot">
                <img src={coin.item.large} alt={coin.item.name}/>
                <span>{coin.item.name} ({coin.item.symbol})</span>
              </div>
              <div className="right-search-hot">
                <span>#{coin.item.market_cap_rank}</span>
              </div>
            </div>
          </>
        })
      }
    </div>
  </>
}

export default HotCoins;