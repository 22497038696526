import jwt_decode from "jwt-decode";
import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const getCookie = (cname) => {
  return cookies.get(cname) || "";
};

export const setCookie = async (cname, value, options = {}) => {  
  cookies.set(cname, value, {
    path: "/",
    domain: window.location.hostname.includes("localhost") ? "localhost" : ".paradigma.education",
    ...options,
  });
};

export const deleteCookie = (cname, domain) => {
  cookies.remove(cname, {
    path: "/",
    domain: window.location.hostname.includes("localhost") ? "localhost" : ".paradigma.education",
    maxAge: 0,
  });
};

export const getObjectFromCookie = (cname) => {
  const obj = getCookie(cname);

  if (obj === "") {
    return null;
  }

  if (obj) {
    const obj_parsed = obj;
    return obj_parsed;
  }

  return null;
};

export const decodeObjFromCookie = (cname) => {
  const obj = getCookie(cname);
  if (obj) {
    const obj_parsed = jwt_decode(obj);
    return obj_parsed;
  }

  return null;
};

export const getUserDetailsFromCookie = () => {
  const session = getCookie("_session");
  if (session) {
    const sessionRet = jwt_decode(session);
    return sessionRet;
  }
  return null;
};

export const getTokenFromCookie = () => {
  const session = getCookie("_session");
  if (session) {
    const sessionRet = session;
    return sessionRet;
  }

  return null;
};

export const isLogged = () => {
  const session = getCookie("isLogged");
  return session.length > 0;
};
