import React, { useState } from "react";
import chevronRight from "../../../../images/Chevron-Right.svg"

const CardFaq = ({text, title}) => {
  const [show, setShow] = useState(false);
  return <>
    <div className={show ? "card-faq-pro is-expanded" : "card-faq-pro"} onClick={() => setShow(!show)}>
      <div data-w-id="e1c83b6e-bb71-b94f-eaa5-63e6d2bd3c79" className="card-faq">
        <div className="text-size-large text-weight-semibold"style={{fontSize: "16px"}}>{title}</div>
        <img src={chevronRight} loading="lazy" alt="" className="icon-seta"/>
      </div>
      <div className="card-faq-wrapper">
        <div className="text-size-large text-color-dark-gray card-faq-content">{text}</div>
      </div>
    </div>
  </>
}

export default CardFaq;