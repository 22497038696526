export const mapPhone = (phone) => {
  const phoneNum = phone.replace(/\D+/g, "");
  const tempDD = phoneNum.substr(0, 1);
  const finalPhoneDD = phoneNum.substr(0, tempDD === `0` ? 3 : 2);
  const finalPhoneNum = phoneNum.substr(tempDD === `0` ? 3 : 2);

  return {
    ddi: 55,
    ddd: finalPhoneDD,
    number: finalPhoneNum,
  };
};
