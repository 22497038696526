import React, { useState, useEffect } from "react";

const VideoTrackMain = ({
  modals,
  setPrice,
  setPPlan,
  // setPeriod,
  setOldPrice,
  modalType,
}) => {
  const [priceAnual, setPriceAnual] = useState(0);
  const [frequency, setFrequency] = useState(12);
  const [type, setType] = useState("Pro");
  const [plan, setPlan] = useState(5);
  const path = window.location.pathname;

  useEffect(() => {
    if (path.startsWith("/trilhas")) {
      modalType(type);
      setPPlan(plan);
    }
  }, [path, modalType, type, setPPlan, plan]);

  useEffect(() => {
    if (modals.modalType === "Light") {
      setPriceAnual(598.8);
      const getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
      setPrice(getPrice());
      setOldPrice(598.8);
      setFrequency(12);
    } else {
      setPriceAnual(1438.8);
      setOldPrice(1438.8);
      const getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
      setPrice(getPrice());
      setFrequency(12);
    }
  }, [
    priceAnual,
    modals.discount,
    modals.modalType,
    setPrice,
    setOldPrice,
  ]);

  var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const calculateFrequencty = () => {
    let getPrice;
    if (modals.modalType === "Light") {
      getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
    } else {
      getPrice = () => {
        return modals.discount
          ? priceAnual - (priceAnual * modals.discount) / 100
          : priceAnual;
      };
    }
    return formatter.format(getPrice() / frequency);
  };

  const onChangeModalType = (e) => {
    setType(e.target.value);
    modalType(e.target.value);
    if (e.target.value === "Light") {
      setPlan(3);
    } else {
      setPlan(5);
    }
  };

  return (
    <>
      {path.startsWith("/trilhas") && modals.type !== 0 &&
        <div className="flex-landing-page-select-modals justify margin-top margin-small">
          <select
            className="landing-page-select-modals"
            name="mdoalType"
            id="modalType"
            onChange={(e) => onChangeModalType(e)}
          >
            <option value="Pro">Trilha c/ Bônus (Pro)</option>
            <option value="Light">Só a Trilha (Light)</option>
          </select>

          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}>
            {modals.discount !== undefined ? (
              <div
                className="text-size-xxlarge"
                style={{
                  textDecoration: "line-through",
                  display: modals.oldPrice,
                }}
              >
                {formatter.format(modals.oldPrice)}
              </div>
            ) : null}
            <div className="flex-horizontal-center flex-horizontal-end">
              <div className="text-size-xxlarge" style={{ fontSize: "18px" }}>
                {formatter.format(modals.price)}
              </div>
            </div>
            <div className="text-size-regular text-font-manrope text-color-dark-gray text-align-right">
              (equivale {calculateFrequencty()}
              /mês)
            </div>
            {modals.discount !== undefined ? (
              <>
                <div
                  className="text-size-regular text-font-manrope text-color-dark-gray text-align-right"
                  style={{ display: modals.price }}
                >
                  {`Cupom de ${modals.discount}% aplicado.`}
                </div>
              </>
            ) : null}
          </div>
        </div>
      }
    </>
  );
};

export default VideoTrackMain;
