import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";

import api from "../services/global/api-paradigma";
import CallCard from "../components/call-card";
import Footer from "../components/footer";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import Modals from "../components/modals";
import loadingGif from "../videos/loading.gif";
import iconRanking from "../images/icon-ranking.png";
import iconWhatsApp from "../images/icon-zap-home.svg";

const mapState = (state) => ({
  modals: state.modals,
});

const Ranking = ({ modals }) => {
  const [data, setData] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [btnToggled, setBtnToggled] = useState(false);
  const [currentSheet, setCurrentSheet] = useState("All Time");
  // const [badges, setBadges] = useState([]);

  useEffect(() => {
    async function getRanking() {
      try {
        const response = await api.get("/api/v2/coupons/referral/ranking");
        setData(response.data.ranking);
      } catch (e) {
        console.log(e);
      }
    }
    getRanking();
  }, []);

  useEffect(() => {
    if (currentSheet && data) {
      switch (currentSheet) {
        case "All Time":
          setRanking(data.all);
          break;
        default:
          setRanking(data.month);
          break;
      }
    }
  }, [currentSheet, data]);

  // useEffect(() => {
  //   async function getRanking() {
  //     try {
  //       const response = await api.get("/api/v2/badges");
  //       setBadges(response.data.data);
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }

  //   getRanking();
  // }, []);

  const changeSheet = (sheet) => {
    setCurrentSheet(sheet);
  };

  return (
    <>
      <a
        href={`https://wa.me/${process.env.REACT_APP_COMPANY_CEL}?text=Olá,%20tudo%20bem?`}
        className="icon-whasapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Chat on WhatsApp" src={iconWhatsApp} />
      </a>
      <div className="page-wrapper">
        <MetaTags>
          <title>Paradigma Education</title>
          <meta
            name="description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
          <meta
            property="og:title"
            content="A vanguarda do conhecimento em Bitcoin & Criptomoedas"
          />
          <meta
            property="og:description"
            content="Produzimos pesquisa original e análises acionáveis sobre criptomoedas. Testamos os produtos mais inovadores do mercado para trazer o alpha até você. Nosso conteúdo é como nenhum outro no Brasil. Tire a prova você mesmo."
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header page-ranking">
            <HeaderMenu current="ranking" />
            <div className="page-padding">
              <div className="container-large padding-top padding-xxlarge">
                <div className="main-grid page-ranking">
                  <div
                    id="w-node-_9fe109cd-84dd-4ee2-b0fe-8a75bc8bc482-35152179"
                    className="grid-wrapper padding-bottom padding-xlarge"
                  >
                    <h1 className="heading-xhuge">Paradigmer do Mês 👑</h1>
                    <div className="text-size-large text-color-dark-gray margin-top margin-xsmall">
                      Todo mês, o/a mais engajado ganha um brinde exclusivo.{" "}
                      <a
                        href="https://paradigma.docsend.com/view/gfks9nd5v9hhvvuz"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-color-medium-green link-decoration"
                      >
                        Veja como participar
                      </a>
                      . Veja também o presente do mês no{" "}
                      <a
                        href="https://twitter.com/ParadigmaEdu"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-color-medium-green link-decoration"
                      >
                        nosso Twitter
                      </a>
                      .
                    </div>
                  </div>
                </div>

                <div className="ranking-wrapper">
                  <div
                    className="buttons-wrapper margin-gap-0"
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      data-w-id="9ff8e964-b476-3489-ee31-6fafc894c39b"
                      href="!#"
                      className="button-small w-inline-block"
                      style={{
                        backgroundColor:
                          currentSheet === "Monthly"
                            ? "#3de8a0"
                            : "transparent",
                        color:
                          currentSheet === "Monthly" ? "#126c46" : "#6b6978",
                      }}
                      onClick={() => changeSheet("Monthly")}
                    >
                      <div>Mês</div>
                    </div>
                    <div
                      data-w-id="95ab5dd4-409c-ea99-95b4-99ebc4f8f5f7"
                      href="!#"
                      className="button-small-secondary w-inline-block"
                      style={{
                        backgroundColor:
                          currentSheet === "All Time"
                            ? "#3de8a0"
                            : "transparent",
                        color:
                          currentSheet === "All Time" ? "#126c46" : "#6b6978",
                      }}
                      onClick={() => changeSheet("All Time")}
                    >
                      <div>All-time</div>
                    </div>
                  </div>
                  <div className="margin-top margin-xsmall ranking-wrapper-scroll">
                    <div className="ranking-grid">
                      <div className="ranking-box margin-left margin-xxsmall">
                        <div className="text-size-small text-color-dark-gray">
                          #
                        </div>
                      </div>
                      {/* <div className="ranking-box margin-left margin-xsmall">
                        <div className="text-size-small text-color-dark-gray text-style-allcaps">
                          Badges
                        </div>
                      </div> */}
                      {/* <div
                        id="w-node-_64cc9bca-a0da-c744-a9c2-fc9574167857-35152179"
                        className="ranking-box margin-left margin-xsmall"
                      >
                        <div className="text-size-small text-color-dark-gray text-style-allcaps">
                          Quantidade
                        </div>
                      </div> */}
                      <div
                        id="w-node-df28067c-ced5-f3de-b7b7-59659bdbff8f-35152179"
                        className="ranking-box margin-left margin-xsmall"
                      >
                        <div className="text-size-small text-color-dark-gray text-style-allcaps">
                          Email
                        </div>
                      </div>
                      <div
                        id="w-node-c4ef3e60-eb01-df33-f46f-fe41ccdf3999-35152179"
                        className="ranking-box margin-left margin-xsmall"
                      >
                        <div className="text-size-small text-color-dark-gray text-style-allcaps">
                          Desde
                        </div>
                      </div>
                      <div
                        id="w-node-_64cc9bca-a0da-c744-a9c2-fc9574167857-35152179"
                        className="ranking-box margin-left margin-xsmall"
                      >
                        <div className="text-size-small text-color-dark-gray text-style-allcaps">
                          Referrals
                        </div>
                      </div>
                      <div
                        id="w-node-df28067c-ced5-f3de-b7b7-59659bdbff92-35152179"
                        className="divisor"
                      ></div>
                    </div>

                    {ranking &&
                      Object.entries(ranking)
                        .sort(function compare(a, b) {
                          if (a[1].quantity > b[1].quantity) return -1;
                          if (a[1].quantity < b[1].quantity) return 1;
                          return 0;
                        })
                        .slice(0, btnToggled ? 1000000 : 5)
                        .map((rankElement, index) => {
                          const mail = rankElement[0].split(" ")[0];
                          const whereIsAt = mail.search("@");
                          const realMail = `${mail.slice(
                            0,
                            3,
                          )}********${mail.slice(
                            whereIsAt,
                            whereIsAt + 3,
                          )}************* `;
                          return (
                            <>
                              <div className="ranking-grid column">
                                <div
                                  id="w-node-df28067c-ced5-f3de-b7b7-59659bdbff94-35152179"
                                  className="divisor"
                                ></div>

                                <div className="ranking-box margin-left margin-xxsmall">
                                  <div className="text-size-small text-color-dark-gray">
                                    {index + 1}
                                  </div>
                                </div>

                                <div className="ranking-box margin-left margin-xsmall">
                                  <div className="text-size-medium text-weight-medium text-color-dark-gray">
                                    {realMail}
                                  </div>
                                </div>

                                <div
                                  id="w-node-df28067c-ced5-f3de-b7b7-59659bdbff9f-35152179"
                                  className="ranking-box margin-left margin-xsmall"
                                >
                                  <div className="text-size-medium text-weight-medium text-color-dark-gray">
                                    {rankElement[1]?.createdOn}
                                  </div>
                                </div>

                                <div
                                  id="w-node-df28067c-ced5-f3de-b7b7-59659bdbffa2-35152179"
                                  className="ranking-box margin-left margin-xsmall"
                                >
                                  <div className="text-size-medium text-weight-medium text-color-dark-gray">
                                    {rankElement[1].quantity}
                                  </div>
                                </div>
                              </div>
                            </>
                          );
                        })}

                    {!ranking ? (
                      <div style={{ paddingTop: 100, paddingBottom: 100 }}>
                        <div className="loader-main-container">
                          <img
                            className="loader-main"
                            src={loadingGif}
                            alt="loading..."
                          />
                        </div>
                      </div>
                    ) : null}

                    {currentSheet === "All Time" && (
                      <div style={{ marginTop: 20 }}>
                        <div className="buttons-wrapper page-ranking margin-top margin-small">
                          <div
                            id="c949c9c1-2bb2-c312-b953-e9700f9fde72"
                            className="button-large-secondary w-inline-block"
                            onClick={() => setBtnToggled(!btnToggled)}
                          >
                            {btnToggled ? "Ver menos" : "Ver mais"}
                          </div>
                        </div>
                      </div>
                    )}

                    {currentSheet === "Monthly" && Object.keys(data.month).length === 0 && (
                      <div style={{ marginTop: 30 }}>
                        <div className="buttons-wrapper page-ranking margin-top margin-small">
                          <div
                            className="w-inline-block flex-center"
                          >
                            <img src={iconRanking} alt="empty ranking" style={{width: "30px"}} />
                            <span style={{color: "#6b6978", marginTop: "10px"}}>Sem dados disponíveis no momento.</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CallCard />
          <Footer />
        </div>
      </div>
      {modals.isOpened && <Modals />}
    </>
  );
};

export default connect(mapState)(Ranking);
