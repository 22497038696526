import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import api from "../../../services/global/api-paradigma";

import PrevArrow from "../../landing-video-tracks/modules/arrow/prev-arrow";
import NextArrow from "../../landing-video-tracks/modules/arrow/next-arrow";

const Reports = () => {
  const [reports, setReports] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: "slide-items",
  };

  useEffect(() => {
    async function getReports() {
      try {
        const response = await api.get("/api/v2/pdfs?kind=website");
        setReports(response.data.data);
      } catch (e) {
        console.log(e);
      }
    }
    getReports();
  }, [reports.length, setReports]);

  return (
    <>
      <div className="carousel">
        {reports.length > 0 && (
          <Slider {...settings}>
            {reports.map((report, index) => (
              <div>
                <a 
                  href={report.url} 
                  target="_blank"
                  useRef="noreferrer"
                  rel="noreferrer"
                  key={index} 
                  className="carousel-container"
                >
                  <img src={report.thumbnail} loading="lazy" alt="" />
                  <div className="margin-top margin-xsmall">
                    <div className="text-size-large text-weight-semibold">
                      <div className="text-reports">{report.name}</div>
                    </div>
                  </div>
                </a>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default Reports;
