import React from "react";
import CardBottom from "./card-bottom";
import CardTop from "./card-top";

const Community = ({ 
  modals, 
  openModal, 
  modalType, 
  landingPageModal 
}) => {
  return (
    <>
      <CardTop />
      <CardBottom 
        modals={modals} 
        openModal={openModal} 
        modalType={modalType} 
        landingPageModal={landingPageModal}
      />
    </>
  );
};

export default Community;

