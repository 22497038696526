import React from "react";
import chevrondownoutline from "../../../images/chevron-down-outline-2-1.svg";

const Menu = ({
  openCurrencySelector, 
  selectCurrency,
  currency,
  isCurrencySelectorOpen,
  categories,
  isCategorySortOpen,
  handleCategorySelect,
  openCategorySort,
  selectOriginalOrder,
}) => {

  return <>
    <div className="cripto-menu-row">
      <div 
        data-hover="false" 
        data-delay="0" 
        className="w-dropdown" 
        onClick={openCurrencySelector} 
        style={{zIndex: 901}}
      >
        <div 
          className={
            isCurrencySelectorOpen 
            ? "cripto-dropdown w-dropdown-toggle w--open" 
            : "cripto-dropdown w-dropdown-toggle"
          }
        >
          <div 
            className="text-color-dark-gray text-weight-medium" 
            style={{textTransform: "uppercase"}}
          >
            {currency}
          </div>
          <img src={chevrondownoutline} loading="lazy" alt="" className="icon-small"/>
        </div>
        {isCurrencySelectorOpen ?
          <nav className="dropdown-list w-dropdown-list w--open" style={{cursor: "pointer"}}>    
            <div 
              role="button" 
              onClick={() => selectCurrency("usd", "$")} 
              className="w-dropdown-link"
            >
              USD
            </div>
          </nav> 
        : null }
      </div>

      <div 
        data-hover="false" 
        data-delay="0" 
        className="w-dropdown" 
        role="button" 
        onClick={openCategorySort} 
        style={{zIndex: 901}}
      >
        <div 
          className={
            isCategorySortOpen 
            ? "cripto-dropdown w-dropdown-toggle w--open" 
            : "cripto-dropdown w-dropdown-toggle"
          }
        >
          <div className="text-color-dark-gray text-weight-medium">Categoria</div>
          <img src={chevrondownoutline} loading="lazy" alt="" className="icon-small"/>
        </div>
        {isCategorySortOpen ?
          <div className="dropdown-list-category w--open">
            <div className="dropdown-list2">
              <div className="dropdown-list3">
                {categories.map((category, index) => {
                  return (
                    <div 
                      key={index} 
                      role="button" 
                      className="dropdown-list4" 
                      onClick={() => handleCategorySelect(category)}
                    >
                      <span style={{color: "#222222"}}>
                        {category}
                      </span>
                    </div>
                  )
                })}    
              </div>
            </div>
          </div> 
        : null }
      </div> 

      <div 
        data-hover="false" 
        data-delay="0" 
        className="w-dropdown" 
        onClick={() => selectOriginalOrder("usd", "$")}
      >
        <div className="cripto-dropdown w-dropdown-toggle">
          <div className="text-color-dark-gray text-weight-medium">Ordem Original</div>
        </div> 
      </div>
    </div>
  </>
}

export default Menu;
