import React from "react";
import MetaTags from "react-meta-tags";

import Footer from "../components/footer";
import ActionBar from "../components/header/action-bar";
import HeaderMenu from "../components/header/menu";
import CallCard from "../components/call-card";

// import mosaic from "../images/mosaic-vitalicy.png";
import mosaic from "../images/close-up-page.webp";
import arrowRight from "../images/seta-vitalicy.svg";
import bookExploded from "../images/Book_exploded_final.svg";
import iconWhatsApp from "../images/icon-zap-home.svg";
import videomp4 from "../videos/book_black_bg.mp4";

const legends = [
  "Sobrecapa-pôster",
  "Verniz",
  "Capa de tiragem única",
  // "Fitilho laranja",
  "Carteira embutida",
  "Páginas coloridas",
];

export const Vitalicio = () => {
  const path = window.location.pathname;

  const handlePageChange = (page) => {
    if (page === "chat") {
      window.location.href = "https://discord.gg/9Yd6V9Dw8D";
      return;
    }
  };

  return (
    <>
      <a
        href={`https://wa.me/${process.env.REACT_APP_COMPANY_CEL}?text=Olá,%20tudo%20bem?`}
        className="icon-whasapp"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img alt="Chat on WhatsApp" src={iconWhatsApp} />
      </a>
      <div className="page-wrapper">
        <MetaTags>
          <title>
            {path === "/vitalicio"
              ? "Entre pro nosso clube vitalício"
              : "O primeiro livro-NFT do Mundo"}
          </title>
          <meta
            name="description"
            content="Compre nossa assinatura vitalícia. 
            Ganhe uma edição limitadíssima do primeiro livro-NFT do mundo. Participe da vanguarda da revolução descentralizada."
          />
          <meta
            property="og:title"
            content={
              path === "/vitalicio"
                ? "Entre pro nosso clube vitalício"
                : "O primeiro livro-NFT do Mundo"
            }
          />
          <meta
            property="og:description"
            content="Compre nossa assinatura vitalícia. 
            Ganhe uma edição limitadíssima do primeiro livro-NFT do mundo. Participe da vanguarda da revolução descentralizada."
          />
        </MetaTags>
        <ActionBar />
        <div className="main-wrapper">
          <div className="section_header vitalicy">
            <HeaderMenu current="vitalicio" />
            <div className="page-padding">
              <div className="container-large padding-vertical">
                <div className="flex-vitalicy">
                  <div className="grid-wrapper">
                    <h2 className="heading-xxhuge-vitalicy text-color-white">
                      {path === "/vitalicio"
                        ? "Entre pro nosso clube vitalício"
                        : "O primeiro livro-NFT do Mundo"}
                    </h2>
                    <div className="text-size-large text-color-gray margin-top margin-xsmall">
                      Compre nossa assinatura vitalícia. Ganhe uma edição
                      limitadíssima do primeiro livro-NFT do mundo.
                      <span className="text-color-green">
                        {" "}
                        Participe da vanguarda da revolução descentralizada.
                      </span>
                    </div>
                    <div className="buttons-wrapper margin-top margin-medium">
                      <a
                        href="https://link.pagar.me/lS1edowLDLa"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-green-vitalicy w-inline-block"
                      >
                        <div>eu quero</div>
                        <img
                          src={arrowRight}
                          loading="lazy"
                          alt=""
                          className="icon-xsmall margin-left margin-xxsmall"
                        />
                      </a>
                    </div>
                  </div>
                  <div className="grid-wrapper">
                    <div
                      data-autoplay="true"
                      data-loop="true"
                      data-wf-ignore="true"
                      className="w-background-video-vitalicy w-background-video-atom"
                    >
                      <video
                        autoPlay
                        loop
                        muted
                        playsInline
                        data-wf-ignore="true"
                        data-object-fit="cover"
                      >
                        <source src={videomp4} data-wf-ignore="true" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="section_quote padding-bottom padding-huge">
            <div className="page-padding">
              <div className="container-large padding-top padding-xlarge">
                <h2 className="heading-xlarge vitalicy">
                  ”Um conteúdo excepcional que se destaca entre as melhores
                  publicações mundiais”
                  {path === "/vitalicio" && <span> - Fernando Ulrich</span>}
                </h2>
                <div
                  className="video-trailer"
                  style={{
                    position: "relative",
                    paddingTop: "56.25%",
                  }}
                >
                  <iframe
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                    }}
                    width="640"
                    height="360"
                    src="https://www.youtube.com/embed/OPc8Hl_2ZY8?si=gYDKkxTPQFmScLgm" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allowfullscreen
                    allow="
                      accelerometer; 
                      autoplay; 
                      clipboard-write; 
                      encrypted-media; 
                      gyroscope; 
                      picture-in-picture; 
                      web-share
                    "
                  ></iframe>
                </div>
              </div>
            </div>
          </div>

          <div className="section_explodedView padding-bottom padding-huge">
            <div className="page-padding">
              <div className="container-large">
                <div className="duo-grid">
                  <img loading="lazy" src={bookExploded} alt="book exploded" />
                  <ul>
                    {legends.map((legend, index) => (
                      <li key={index}>
                        <span>{`${index + 1}. `}</span>
                        {legend}
                      </li>
                    ))}
                    <div
                      className="button-green-vitalicy w-inline-block"
                      style={{ cursor: "pointer", marginTop: "25px" }}
                      onClick={() => handlePageChange("chat")}
                    >
                      <div>Tire uma dúvida conosco</div>
                      <img
                        src={arrowRight}
                        loading="lazy"
                        alt=""
                        className="icon-xsmall margin-left margin-xxsmall"
                      />
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="section_mosaic">
            <div className="page-padding">
              <div className="container-large">
                <div className="flex-center">
                  <img src={mosaic} alt="mosaic" style={{ opacity: ".75" }} />
                  <div className="absolute-center">
                    <div className="buttons-wrapper">
                      <a
                        href="https://link.pagar.me/lS1edowLDLa"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button-green-vitalicy w-inline-block"
                      >
                        <div>Garanta o seu</div>
                        <img
                          src={arrowRight}
                          loading="lazy"
                          alt=""
                          className="icon-xsmall margin-left margin-xxsmall"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="section_newsletter stylle-shadow">
            <div className="page-padding">
              <div className="container-large padding-vertical padding-huge">
                <div className="flex-center padding-horizontal padding-large">
                  <h2 className="heading-xlarge margin-bottom margin-xsmall text-color-medium-blue text-align-center">Assine a newsletter gratuita</h2>
                  <div className="text-size-large text-align-center text-color-dark-blue text-center" style={{ maxWidth: "340px" }}>
                    Descubra por que milhares de pessoas em 
                    <span className="text-color-medium-blue text-weight-bold">{" "}mais de 25 países{" "}</span> 
                    leem a
                    <span className="text-color-medium-blue text-weight-bold">{" "}Café com Satoshi</span>.
                  </div>
                  <form className="flex-center-row margin-top margin-small">
                    <input 
                      type="email"
                      placeholder="satoshi@outlook.com"
                      className="text-field w-input"
                      maxLength="256"
                      name="email"
                    />
                    
                      <div
                        className="button-green-vitalicy w-inline-block"
                        style={{ cursor: "pointer" }}
                      >
                        <div>RECEBA GRÁTIS</div>
                        <img
                          src={arrowRight}
                          loading="lazy"
                          alt=""
                          className="icon-xsmall margin-left margin-xxsmall"
                        />
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div> */}

          <CallCard vitalicy />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Vitalicio;
