// descomentar
// import React, { useState } from "react";
// import axios from "axios";


import React from "react";
import setagreen from "../../../../images/seta-light-green.svg";

// tirar title e link do Aticle
const Article = ({title, link}) => {
  // const [articles, setArticles] = useState([]);

  // useEffect(() => {
  //   async function getArticles() {
  //     try {
  //       const response = await axios.get('url');
  //       setArticles(response.data);
        
  //     } catch(e) {
  //       console.log(e);
  //     }
  //   }

  //   getArticles();
  // }, [])

  return <>
  {/* map para listar os articles */}
  {/* {articles.map((article, index) => {
    return <div className="card-faq-pro">
      <div data-w-id="41485098-b357-94a3-8433-767498c6bbdc" className="card-faq">
        <div className="text-size-large text-weight-semibold">{article.title}</div>
        <div className="buttons-wrapper">
          <a data-w-id="75c8f5a5-1b32-6e29-71ac-7dd73849d643" href={article.link} target="_blank" rel="noopener noreferrer" className="button-text w-inline-block">
            <div>Ler Matéria</div>
            <img src={setagreen} loading="lazy" alt="" className="icon-xsmall margin-left margin-xxsmall"/>
          </a>
        </div>
      </div>
    </div>
  })} */}

  {/* sem integração */}
  <div className="card-faq-pro">
    <div data-w-id="41485098-b357-94a3-8433-767498c6bbdc" className="card-faq">
      <div className="text-size-large text-weight-semibold text-section-media">{title}</div>
      <div className="buttons-wrapper">
        <a data-w-id="75c8f5a5-1b32-6e29-71ac-7dd73849d643" href={link} target="_blank" rel="noopener noreferrer" className="button-text w-inline-block button-section-media">
          <div>Ler Matéria</div>
          <img src={setagreen} loading="lazy" alt="" className="icon-xsmall margin-left margin-xxsmall"/>
        </a>
      </div>
    </div>
  </div>


  </>
}

export default Article;