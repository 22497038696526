import React, { useState } from "react";
import { connect } from "react-redux";

import ModalCoupon from "./modal-coupon";
import ModalForm from "./modal-form";
import Message from "./message";
import ModalMain from "./modal-main";
import ModlalChat from "./modal-chat";
import VideoTrackForm from "../modals-video-track/video-track-form";
import VideoTrackCoupon from "../modals-video-track/video-track-coupon";
import VideoTrackMain from "../modals-video-track/video-track-main";
import ModalMainVitalicy from "./modal-main-vitalicy";
import ModalMainBlackFriday from "./modal-main-blackfriday";
import arrow from "../../images/arrow-back-outline-1-1.svg";
import close from "../../images/close-outline-1.svg";
import api from "../../services/global/api-paradigma";
import {
  openModal,
  setModal,
  setEmail,
  setTerms,
  setPrice,
  setDiscount,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
} from "../../store/actions/modals";
import { Link } from "react-router-dom";

const mapState = (state) => ({
  modals: state.modals,
});

const Modals = ({
  modals,
  openModal,
  setModal,
  setEmail,
  setTerms,
  setDiscount,
  setPrice,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
}) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const path = window.location.pathname;

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(null); 
  const [emailValue, setEmailValue] = useState({
    email: "" || urlParams.get("email"),
  });
  const [termsValue, setTermsValue] = useState(false);
  const [visible, setVisible] = useState(false);

  const onTermsChange = (ev) => {
    setTermsValue(ev.target.value);
    setTerms(ev.target.value);
  };

  const onChange = (ev) => {
    const { name, value } = ev.target;
    setEmailValue({ ...emailValue, [name]: value });
  };

  const navigateModal = async (kind) => {
    const validEmail = /.+@.+\.[A-Za-z]+$/.test(emailValue.email);
    if (validEmail && termsValue) {
      setEmail(emailValue.email);
      setModal(kind);
      setVisible(false);
      await api.post("/api/v2/orders", {
        email: modals.email,
        landing: window.location.href,
        coupon: modals.coupon.code,
      });
    } else {
      if (!termsValue) {
        setStatus("error");
        setMessage("Você precisa concorda com os termos para prosseguir!");
      }
      if (!validEmail) {
        setStatus("error");
        setMessage("Digite um email válido");
      }
      setVisible(true);
      openModal(true);
      setModal(0);
    }
  };

  const ModalDisplay = () => {
    if (modals.type === 0) {
      if (path.startsWith("/trilhas")) {
        return (
          <VideoTrackMain
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
          />
        );
      } else if (path === "/vitalicio") {
        return (
          <ModalMainVitalicy
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
          />
        );
      } else if (path === "/pagamento") {
        return (
          <ModalMainBlackFriday
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
          />
        );
      } else {
        return (
          <ModalMain
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
          />
        );
      }
    } else if (modals.type === 1) {
      if (path.startsWith("/trilhas")) {
        return (
          <VideoTrackForm
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
            setInstallments={setInstallments}
          />
        );
      } else {
        return (
          <ModalForm
            modals={modals}
            openModal={openModal}
            setPrice={setPrice}
            setPPlan={setPPlan}
            setPeriod={setPeriod}
            setOldPrice={setOldPrice}
            modalType={modalType}
            landingPageModal={landingPageModal}
            setInstallments={setInstallments}
          />
        );
      }
    } else if (modals.type === 2) {
      return <ModlalChat modals={modals} openModal={openModal} />;
    } else {
      if (path.startsWith("/trilhas")) {
        return (
          <VideoTrackCoupon
            modals={modals}
            setPrice={setPrice}
            setModal={setModal}
            setDiscount={setDiscount}
            setOldPrice={setOldPrice}
            setCoupon={setCoupon}
            setCodeId={setCodeId}
            setBlackFriday={setBlackFriday}
            landingPageModal={landingPageModal}
          />
        );
      } else {
        return (
          <ModalCoupon
            modals={modals}
            setPrice={setPrice}
            setModal={setModal}
            setDiscount={setDiscount}
            setOldPrice={setOldPrice}
            setCoupon={setCoupon}
            setCodeId={setCodeId}
            setBlackFriday={setBlackFriday}
            landingPageModal={landingPageModal}
          />
        );
      }
    }
  };

  return (
    <>
      <div
        className={path === "/" ? "modal-wrapper-blackfriday" : "modal-wrapper"}
        style={{ backgroundColor: path === "/pagamento" && "#000" }}
      >
        <div className="modal-container padding-xsmall">
          <div className="flex-modal">
            <div
              className="icon-back-wrapper"
              onClick={() => {
                navigateModal(0);
              }}
            >
              <img
                src={arrow}
                loading="lazy"
                alt=""
                className={
                  modals.type === 0 ? "icon-back display-none" : "icon-back"
                }
              />
            </div>

            <img
              src={close}
              loading="lazy"
              alt=""
              className="icon-close"
              style={{ display: path === "/pagamento" && "none" }}
              onClick={() => openModal(false)}
            />
          </div>
          <div className={modals.type === 0 ? "form-wrapper-scroll-modal" : ""}>
            <div className="modal-inner modal-inner-padding">
              {ModalDisplay()}
            </div>
            {modals.type === 0 ? (
              <div className="form-wrapper w-form">
                {visible ? <Message text={message} status={status} /> : null}
                <form
                  id="email-form"
                  name="email-form"
                  data-name="Email Form"
                  className="form"
                  required
                >
                  <input
                    type="email"
                    className="text-field w-input"
                    maxLength="256"
                    name="email"
                    value={emailValue.email}
                    data-name="Email"
                    placeholder="Seu email"
                    onChange={onChange}
                    id="email"
                    required
                  />
                  <div className="checkbox-terms-of-use">
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="terms"
                      checked={termsValue}
                      data-name="Terms"
                      onChange={(e) => {
                        onTermsChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        });
                      }}
                      id="terms"
                      required
                    />
                    <span>
                      Concordo com os
                      <a
                        href="https://paradigma.docsend.com/view/ku5u6ak"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-size-medium text-color-black"
                      >
                        Termos de uso e
                      </a>
                      <a
                        href="https://paradigma.docsend.com/view/82h48x9"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-size-medium text-color-black margin-horizontal margin-medium"
                      >
                        Política de dados
                      </a>
                    </span>
                  </div>

                  <div className="flex-vertical">
                    <button
                      data-w-id="910f1fe2-8b71-d0a5-ba88-8461bcfb4123"
                      className="button-modal margin-vertical margin-xxsmall w-inline-block"
                      onClick={() => {
                        navigateModal(1);
                      }}
                    >
                      <span>Pagar com Cartão</span>
                    </button>
                    <div
                      data-w-id="fe0bc053-d995-ca1e-f660-685f58c5d0d4"
                      className="button-white-small w-inline-block"
                      style={{
                        cursor: "pointer",
                        display: path === "/pagamento" && "none",
                      }}
                      onClick={() => {
                        navigateModal(3);
                      }}
                    >
                      <div>Tenho cupom de desconto</div>
                    </div>
                    <Link to="/pro" target="_blank" rel="noopener noreferrer">
                      <div className="text-font-manrope text-color-dark-gray modal-text-align">
                        <div>Quero ver depoimentos de assinantes</div>
                      </div>
                    </Link>
                  </div>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapState, {
  setModal,
  openModal,
  setEmail,
  setTerms,
  setPrice,
  setDiscount,
  setPPlan,
  setPeriod,
  setOldPrice,
  setCoupon,
  setCodeId,
  setBlackFriday,
  modalType,
  landingPageModal,
  setInstallments,
})(Modals);
