import React from "react";
import "../../styles/landing-tracks.css";
import trophy from "../../images/trophy-outline-16px.svg";

const CollectibleBadge = ({ data }) => {
  if (data?.badges.length > 0)
    return (
      <>
        <div className="section-collectible-badge">
          <div className="page-padding">
            <div className="container-large padding-vertical padding-huge">
              <div className="flex-center padding-horizontal padding-large">
                <div className="container-collectible-badge">
                  <div
                    className="landing-tracks-text-primary-large"
                    style={{ paddingBottom: "24px" }}
                  >
                    Conclua esta trilha e desbloqueie
                  </div>
                  <div className="container-badge">
                    <div className="container-background-icon">
                      <img
                        src={data?.badges[0].icons.icon16x}
                        alt="badge"
                        className="icon-badge"
                      />
                      <div className="icon-trophy">
                        <img src={trophy} alt="trophy" />
                      </div>
                    </div>
                    <div className="container-name-badge">
                      <div className="name">Badge Colecionável</div>
                      <div className="classification">
                        {data?.badges[0].name.toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default CollectibleBadge;
