export const landingPageModal = (landingPageModal) => async (dispatch) => {
  try {
    dispatch({
      type: "LANDING_PAGE_MODAL",
      payload: landingPageModal,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setBlackFriday = (state) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_BLACK_FRIDAY",
      payload: state,
    });
  } catch (err) {
    console.log(err);
  }
};

export const openModal = (state) => async (dispatch) => {
  console.log(state);
  try {
    dispatch({
      type: "OPEN_MODAL",
      payload: state,
    });
  } catch (err) {
    console.log(err);
  }
};

export const openChatModal = (open, modalType, type) => async (dispatch) => {
  try {
    dispatch({
      type: "OPEN_MODAL",
      payload: open,
    });
    dispatch({
      type: "MODAL_TYPE",
      payload: modalType,
    });
    dispatch({
      type: "SET_MODAL",
      payload: type,
    });
  } catch (err) {
    console.log(err);
  }
};

export const modalType = (modalType) => async (dispatch) => {
  try {
    dispatch({
      type: "MODAL_TYPE",
      payload: modalType,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setModal = (kind) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_MODAL",
      payload: kind,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setTerms = (terms) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_TERMS",
      payload: terms,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setEmail = (email) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_EMAIL",
      payload: email,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setPrice = (price) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_PRICE",
      payload: price,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setDiscount = (percent) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_DISCOUNT",
      payload: percent,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setPeriod = (period) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_PERIOD",
      payload: period,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setPPlan = (pplan) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_PPLAN",
      payload: pplan,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setOldPrice = (price) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_OLD_PRICE",
      payload: price,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setCodeId = (codeId) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_CODE_ID",
      payload: codeId,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setCoupon = (coupon) => async (dispatch) => {
  try {
    dispatch({
      type: "SET_COUPON",
      payload: coupon,
    });
  } catch (err) {
    console.log(err);
  }
};

export const setInstallments = (installments) => async (dispatch) => {
  try {
    console.info("installments", installments);
    dispatch({
      type: "SET_INSTALLMENTS",
      payload: installments,
    });
  } catch (err) {
    console.log(err);
  }
};
