import React from "react";
import Slider from "react-slick";

import PrevArrow from "./modules/arrow/prev-arrow";
import NextArrow from "./modules/arrow/next-arrow";
import author4 from "../../images/author-large-image-04@2x.png";
import { resizeImage } from "../../utils/resize-image";

import "../../styles/landing-tracks.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Track = ({
  thumbnail,
  name,
  videosDuration,
  modulesCount,
  soon,
  slug,
  producer,
}) => {
  return (
    <>
      <div className="card-track">
        {soon ? (
          <div className="landing-tracks-text-primary-large soon-text">
            Em Breve...
          </div>
        ) : null}
        <div
          className={`${soon ? "text-filter-blur" : null} track-container-text`}
        >
          <div
            className="landing-tracks-text-primary-large"
            style={{ paddingBottom: "8px" }}
          >
            {name}
          </div>

          <p className="landing-tracks-text-primary-xx-small">
            {`${producer} • ${modulesCount} Módulos • ${videosDuration?.replace(
              /:\d+s/,
              ""
            )}`}
          </p>

          <a
            className="landing-tracks-text-primary-xx-small"
            href={`/trilhas/${slug}`}
          >
            <button className="landing-tracks-text-primary-xx-small">
              Saiba mais
            </button>
          </a>
        </div>
        <div className="track-image-gradient"></div>
        <img
          className={soon ? "card-filter-blur" : null}
          src={thumbnail}
          alt="author"
        />
      </div>
    </>
  );
};

const Tracks = ({ data }) => {
  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    className: "slide-items",
  };

  return (
    <>
      <div className="section-tracks">
        <div className="page-padding-left-tracks">
          <div className="container-large padding-top-bottom">
            <div
              className="page-padding-right-tracks"
              style={{ textAlign: "center" }}
            >
              <div
                className="landing-tracks-text-primary-x-large"
                style={{ marginBottom: "8px" }}
              >
                Compre esta trilha e acesse também👇
              </div>

              <p
                className="landing-tracks-text-second-medium"
                style={{ marginBottom: "48px" }}
              >
                Com um só pagamento, você libera mais {data.length} trilhas com
                conteúdo exclusivo
              </p>
            </div>

            <div className="paradigma-tracks">
              {data.length > 0 && (
                <Slider {...settings}>
                  {data.map((item, index) => (
                    <Track
                      key={index}
                      thumbnail={resizeImage(item?.portraitThumbnail, 0)}
                      name={item?.name}
                      modulesCount={item.modulesCount || 0}
                      videosDuration={item.videosDuration || 0}
                      slug={item.slug}
                      producer={item.producer}
                    />
                  ))}
                  {data.length < 5 ? (
                    <Track thumbnail={author4} soon={true} />
                  ) : null}
                </Slider>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tracks;
