import React from "react";

function Button({title, id, namesClass, link, color}) {
  return <>
    <a data-w-id={id} href={link} target="_blank" rel="noopener noreferrer" style={{cursor: "pointer"}} className={namesClass}>
      <div className={color}>{title}</div>
    </a>
  </>;
}

export default Button;
