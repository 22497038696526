import React from "react";

import VideoTrackStripe from "./video-track-stripe";

const VideoTrackForm = ({
  modals,
  setPrice,
  setPPlan,
  setPeriod,
  setOldPrice,
  modalType,
  openModal,
  landingPageModal,
  setInstallments,
}) => {
  const getSelectInstallmentsDefaultValue = () => {
    const priceDiscount =
      modals.oldPrice - (modals.oldPrice * modals.discount) / 100;
    const oldPrice = modals.discount ? priceDiscount : modals.oldPrice;
    return (oldPrice / modals.installments).toFixed(2);
  };

  const installmentsOptions = (numberInstallment) => {
    const priceDiscount =
      modals.oldPrice - (modals.oldPrice * modals.discount) / 100;
    const oldPrice = modals.discount ? priceDiscount : modals.oldPrice;
    const totalInstallment = [];
    for (let i = 1; i <= numberInstallment; i++) {
      totalInstallment.push(
        <option key={i} value={(oldPrice / i).toFixed(2)}>
          {`${i}x R$${(oldPrice / i).toFixed(2)}`}
        </option>,
      );
    }
    return totalInstallment;
  };

  return (
    <>
      <VideoTrackStripe
        modals={modals}
        setPrice={setPrice}
        setPPlan={setPPlan}
        setPeriod={setPeriod}
        setOldPrice={setOldPrice}
        modalType={modalType}
        openModal={openModal}
        landingPageModal={landingPageModal}
        setInstallments={setInstallments}
        installmentsOptions={installmentsOptions}
        getSelectInstallmentsDefaultValue={getSelectInstallmentsDefaultValue}
      />
    </>
  );
};

export default VideoTrackForm;
