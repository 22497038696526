import React from "react";

import iconRight from "../../../../images/chevron-forward-24px.svg";

const NextArrow = (props) => {
  const {className, onClick} = props;
  return <>
    <div 
      className={className} 
      onClick={onClick}
    >
    <img src={iconRight} alt="arrow right" />
    </div>
  </>
}

export default NextArrow;