import React from "react";

// import column1 from "../../../images/Column-1.png";
// import column2 from "../../../images/Column-2.png";
// import column3 from "../../../images/Column-3.png";
// import column4 from "../../../images/Column-4.png";

const Depositions = () => {
  return <>
    <div className="section background-color-darkest-gray teste">
      <div className="page-padding">
        <div className="container-large padding-vertical padding-huge">
          <h2 className="heading-xlarge text-align-center text-color-white">Escute quem nos segue</h2>
        </div>
      </div>
      {/* <div className="flex-horizontal justify">
        <div className="column-image">
          <img src={column1} loading="lazy" style={{
          WebkitTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MozTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MsTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          transform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
          }} data-w-id="af1a6881-4978-e357-467d-c6617c286bac" alt="" className="cover-image cover-image-1"/> 
        </div>
        <div className="column-image">
          <img src={column2} loading="lazy" style={{
          WebkitTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MozTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MsTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          transform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
          }} data-w-id="af1a6881-4978-e357-467d-c6617c286bac" alt="" className="cover-image cover-image-2"/> 
        </div>
        <div className="column-image">
          <img src={column3} loading="lazy" style={{
          WebkitTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MozTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MsTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          transform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
          }} data-w-id="af1a6881-4978-e357-467d-c6617c286bac" alt="" className="cover-image cover-image-3"/> 
        </div>
        <div className="column-image">
          <img src={column4} loading="lazy" style={{
          WebkitTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MozTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          MsTransform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)',
          transform: 'translate3d(0, 0%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'
          }} data-w-id="af1a6881-4978-e357-467d-c6617c286bac" alt="" className="cover-image cover-image-4"/> 
        </div>
      </div> */}
      <div className="flex-horizontal justify">
      <div className="column-image">
        <div className="cover-image cover-image-1 animation-image-column1"></div>
      </div>
      <div className="column-image column-image-2">
        <div className="cover-image cover-image-2 animation-image-column2"></div>
      </div>
      <div className="column-image column-image-3">
        <div className="cover-image cover-image-3 animation-image-column3"></div>
      </div>
      <div className="column-image column-image-4">
        <div className="cover-image cover-image-4 animation-image-column4"></div>
      </div>
    </div>
    </div>
  </>
}

export default Depositions;