import React, { useState, useEffect } from "react";

import { intervalToDuration } from "date-fns";

import iconBrazil from "../../../images/flag-brazil.png";

const ActionBar = ({ isTrack, isBlackFriday }) => {
  const [duration, setDuration] = useState({});
  // const path = window.location.pathname;

  useEffect(() => {
    setInterval(() => {
      setDuration(
        intervalToDuration({
          start: new Date(),
          end: new Date("2024-12-06T23:59:59"),
        }),
      );
    }, 1000);
  }, []);

  return (
    <>
      <div
        className={
          isTrack
            ? "stickbar-section stickbar-section-tracks"
            : isBlackFriday
              ? "stickbar-section stickbar-section-blackFriday"
              : "stickbar-section"
        }
      >
        <div className="page-padding">
          <div className="text-size-medium text-color-white">
            {isBlackFriday ? (
              <span>
                <span style={{ fontWeight: "bold" }}>⏰ FALTAM</span>
                <span style={{ color: "#fff" }}>{` ${duration.days || "0"}d ${
                  duration.hours || "0"
                }h ${duration.minutes || "0"}m ${
                  duration.seconds || "00"
                }s`} | </span>
                <span style={{ fontWeight: "bold", color: "#3de8a0" }}>
                  {" "}
                  CYBER WEEK:
                </span>
                <span>MELHOR OFERTA DO ANO ACABA NA SEXTA</span>
              </span>
            ) : (
              <>
                <img
                  src={iconBrazil}
                  width="13"
                  height="13"
                  alt="Bandeira Brasil"
                  style={{ marginRight: "10px" }}
                />
                <span>
                  O único conteúdo brasileiro consumido pelos maiores
                  participantes globais do mercado.
                </span>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionBar;
