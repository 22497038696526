import axios from "axios";

const API_URL =
  typeof window === "object"
    ? window.location.origin.search("staging") !== -1
      ? "https://api-staging.paradigma.education/"
      : "https://api.paradigma.education/"
    : null;

const api = axios.create({
  baseURL:
    window.location.origin.search("localhost") === -1
      ? API_URL
      : "http://localhost:8080/",
});

export default api;
